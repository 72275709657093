import Content from '@/layout/Content.vue';

const ProductReviewList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "ProductReview" */ '@/domain/productReview/views/ProductReviewList.vue'
  );

export const productReviewPathList = {
  path: '/product-review',
  name: 'ProductReviewContent',
  redirect: '/product-review/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'ProductReviewList',
      component: ProductReviewList,
      meta: { title: '후기관리' },
    },
  ],
};
