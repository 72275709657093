<template>
  <div class="content">
    <!-- TODO: 일단은 임시로 추가해놓은것이기 때문에 추후 수정해야함-->
    <div class="bg-white wg-100 p-3 font-weight-bold">
      {{ pageName }}
    </div>

    <FadeTransition :duration="200" mode="out-in">
      <!-- your content here -->
      <router-view class="m-3" :key="$route.path"></router-view>
    </FadeTransition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';
export default {
  components: {
    FadeTransition,
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pageName() {
      return this.$route.meta.title || '제목 없음';
    },
  },
};
</script>
<style></style>
