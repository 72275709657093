import gql from 'graphql-tag';
import axios from 'axios';
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { apolloClient } from '@/apolloClient';

const GENEREATE_UPLOAD_INFO_MUTATION = gql`
  mutation GenerateContentUploadInfo($param: ContentUploadParam!) {
    generateContentUploadInfo(param: $param) {
      contentId
      uploadUrl
      formData {
        key
        value
      }
    }
  }
`;

export const FINALIZE_CONTENTS_AND_TARGET = gql`
  mutation finalizeContentsAndTarget($param: [ContentTargetWithIdParam!]!) {
    finalizeContentsAndTarget(targets: $param) {
      id
      type
      width
      height
      uri
    }
  }
`;

export const FINALIZE_CONTENTS = gql`
  mutation finalizeContents($contentIds: [ID!]!) {
    finalizeContents(contentIds: $contentIds) {
      id
      type
      width
      height
      uri
      thumbnail
    }
  }
`;

interface ContentUploadParam {
  fileName: string;
  target?: string;
  targetId?: string;
}
interface ContentUploadInfoItem {
  key: string;
  value: string;
}

interface ContentUploadInfo {
  contentId: string;
  uploadUrl: string;
  formData: ContentUploadInfoItem[];
}

interface UploadedImage {
  image: Image;
  contentId: string;
}

interface Image {
  access_mode: string;
  api_key: string;
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  format: string;
  height: number;
  original_filename: string;
  placeholder: boolean;
  public_id: string;
  resource_type: string;
  secure_url: string;
  signature: string;
  tags: string[];
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
}

export enum ContentTarget {
  USER_PROFILE = 'USER_PROFILE',
  HOST_PROFILE = 'HOST_PROFILE',
  HOST_ID_CARD = 'HOST_ID_CARD',
  PRODUCT_HEADER = 'PRODUCT_HEADER',
  BANNER = 'BANNER',
  COLLECTION = 'COLLECTION',
  SHORTCUT = 'SHORTCUT',
  ETC = 'ETC',
}

enum ContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  HTML = 'HTML',
  TEXT = 'TEXT',
}
export interface ContentTargetWithIdParam {
  contentId: string;
  target: ContentTarget;
  targetId: string;
}

interface Content {
  id: string;
  type: ContentType;
  width: number;
  height: number;
  uri: string;
}

export class ImageUploadService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }
  public async getUploadInfo(
    contentUploadInfoParam: ContentUploadParam
  ): Promise<ContentUploadInfo> {
    const result = await this.apollo.mutate({
      mutation: GENEREATE_UPLOAD_INFO_MUTATION,
      variables: {
        param: contentUploadInfoParam,
      },
    });

    const uploadInfo = result.data?.generateContentUploadInfo;
    console.log(uploadInfo);
    return uploadInfo;
  }

  public makeFormForUpload(
    file: File,
    uploadInfo: ContentUploadInfo
  ): FormData {
    const form = new FormData();
    uploadInfo.formData.forEach((element: ContentUploadInfoItem) => {
      form.append(element.key, element.value);
    });
    form.append('file', file);
    return form;
  }

  async uploadImage(file: File): Promise<UploadedImage> {
    console.log('file: ', file);
    const contentUploadInfoParam = {
      fileName: file.name,
    };

    const uploadInfo = await this.getUploadInfo(contentUploadInfoParam);
    const form = this.makeFormForUpload(file, uploadInfo);
    const uploadedImage = await axios.post(uploadInfo.uploadUrl, form, {
      headers: {},
    });
    console.log('uploadedImage: ', uploadedImage);
    return {
      image: uploadedImage.data,
      contentId: uploadInfo.contentId,
    };
  }

  async finalizeContentsAndTarget(
    params: ContentTargetWithIdParam[]
  ): Promise<Content[]> {
    const result = await this.apollo.mutate({
      mutation: FINALIZE_CONTENTS_AND_TARGET,
      variables: {
        param: params,
      },
    });
    const uploadInfo = result.data?.Content;
    return uploadInfo;
  }

  async finalizeContents(contentIds: string[]): Promise<Content[]> {
    console.log(contentIds);
    const result = await this.apollo.mutate({
      mutation: FINALIZE_CONTENTS,
      variables: {
        contentIds: contentIds,
      },
    });

    const uploadInfo = result.data?.Content;
    return uploadInfo;
  }
}

export const imageUploadService = new ImageUploadService(apolloClient);
