import Content from '@/layout/Content.vue';
import { NotificationMessageSender } from './model/notificationMessage';

const NotificationMessageList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "point" */ '@/domain/notificationMessage/views/NotificationMessageList.vue'
  );

export const notificationMessagePathList = {
  path: '/notificationMessage',
  name: 'NotificationMessageContent',
  component: Content,
  children: [
    {
      path: 'list/user',
      name: 'UserNotificationMessageList',
      component: NotificationMessageList,
      meta: { title: '크루 발송내역', sender: NotificationMessageSender.FRIP },
    },
    {
      path: 'list/host',
      name: 'HostNotificationMessageList',
      component: NotificationMessageList,
      meta: {
        title: '호스트 발송내역',
        sender: NotificationMessageSender.FRIP_HOST,
      },
    },
  ],
};
