import Content from '@/layout/Content.vue';
import IntroPopupDetail from '@/domain/introPopup/views/IntroPopupDetail.vue';

const IntroPopupListContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/introPopup/views/IntroPopupListContainer.vue');

export const introPopupPathList = {
  path: '/introPopup',
  name: 'IntroPopupContent',
  redirect: '/introPopup/list',
  component: Content,
  children: [
    {
      path: '/introPopup/list',
      name: 'IntroPopupListContainer',
      component: IntroPopupListContainer,
      meta: { title: '마케팅 팝업 관리' },
    },
    {
      path: '/introPopup/detail/:id',
      name: 'IntroPopupDetail',
      component: IntroPopupDetail,
      meta: { title: '마케팅 팝업 상세' },
    },
    {
      path: '/introPopup/register',
      name: 'IntroPopupRegister',
      component: IntroPopupDetail,
      meta: { title: '마케팅 팝업 등록' },
    },
  ],
};
