import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import axios, { AxiosResponse } from 'axios';
import { getAuthUrl, isHostAdmin } from '@/env';
import {
  LoginParam,
  SocialLoginParam,
  SocialSignUpParam,
  SignUpParam,
} from '@/domain/login/model/identity';

Vue.use(Vuex);

interface UserToken {
  tokenType: string;
  accessToken: string;
  authorityLevel: string;
  userId: number;
  hostId: string;
  email: string;
  name: string;
  expiresIn: number;
  joinedAt: string;
}

interface TokenAxiosResponse {
  data?: UserToken;
}

interface State {
  authStatus: string;
  accessToken: string;
  userToken: UserToken | null;
  isMobile: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

function parseUserToken(str: string | null): UserToken | null {
  if (!str) {
    return null;
  }

  try {
    return JSON.parse(str);
  } catch (e) {
    console.error('parseUserToken:', e);
    return null;
  }
}

const state: State = {
  authStatus: '',
  accessToken: localStorage.getItem('accessToken') || '',
  userToken: parseUserToken(localStorage.getItem('userToken')),
  isMobile: window.innerWidth < 768, // 모바일 기기
};

const mutations = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(state: State, [variable, value]: [string, any]) {
    state[variable] = value;
  },
  auth_request(state: State) {
    state.authStatus = 'loading';
  },
  auth_success(state: State, tokenResult?: AxiosResponse<TokenAxiosResponse>) {
    const accessToken: string = tokenResult?.data?.data?.accessToken || '';
    const userToken: UserToken | null = tokenResult?.data?.data || null;
    localStorage.setItem('accessToken', accessToken);
    if (userToken) {
      localStorage.setItem('userToken', JSON.stringify(userToken));
    }

    state.authStatus = 'success';
    state.accessToken = accessToken;
  },
  auth_error(state: State) {
    state.authStatus = 'error';
  },
  logout(state: State) {
    state.authStatus = '';
    state.accessToken = '';
    state.me = null;
  },
};

const actions = {
  async login(
    { commit }: ActionContext<State, State>,
    param: LoginParam
  ): Promise<UserToken | null> {
    commit('auth_request');
    try {
      const tokenResult: AxiosResponse<TokenAxiosResponse> = await axios({
        url: `${getAuthUrl()}/signin`,
        data: {
          ...param,
          authorityLevel: param.byUser
            ? 'USER'
            : isHostAdmin()
            ? 'HOST'
            : 'ADMIN',
          store: 'frip',
        },
        method: 'POST',
      });
      commit('auth_success', tokenResult);
      return tokenResult?.data?.data || null;
    } catch (e) {
      commit('auth_error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userToken');
      throw e;
    }
  },
  logout({ commit }: ActionContext<State, State>) {
    commit('logout');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userToken');
  },
  async signUp(
    { commit }: ActionContext<State, State>,
    param: SignUpParam
  ): Promise<UserToken | null> {
    commit('auth_request');
    try {
      const tokenResult = await axios({
        url: `${getAuthUrl()}/signup`,
        data: {
          ...param,
        },
        method: 'POST',
      });
      commit('auth_success', tokenResult);
      return tokenResult?.data?.data || null;
    } catch (e) {
      commit('auth_error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userToken');
      console.log(e);
      throw e;
    }
  },

  async socialLogin(
    { commit }: ActionContext<State, State>,
    param: SocialLoginParam
  ): Promise<void> {
    commit('auth_request');
    try {
      const tokenResult = await axios({
        url: `${getAuthUrl()}/link/signin`,
        data: {
          ...param,
          authorityLevel: param.byUser
            ? 'USER'
            : isHostAdmin()
            ? 'HOST'
            : 'ADMIN',
          store: 'frip',
        },
        method: 'POST',
      });
      commit('auth_success', tokenResult);
      return tokenResult?.data?.data || null;
    } catch (e) {
      commit('auth_error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userToken');
      throw e;
    }
  },
  async socialSignUp(
    { commit }: ActionContext<State, State>,
    param: SocialSignUpParam
  ): Promise<UserToken | null> {
    commit('auth_request');
    try {
      const tokenResult = await axios({
        url: `${getAuthUrl()}/link/signup`,
        data: {
          ...param,
          authorityLevel: 'USER',
          store: 'frip',
        },
        method: 'POST',
      });
      commit('auth_success', tokenResult);
      return tokenResult?.data?.data || null;
    } catch (e) {
      commit('auth_error');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userToken');
      throw e;
    }
  },
};

const getters = {
  isLoggedIn: (state: State) => {
    return state.accessToken.length > 0;
  },
  isHostLoggedIn: (state: State) => {
    return !!state.userToken?.hostId;
  },
  authStatus: (state: State) => state.authStatus,
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});
