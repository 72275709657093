import Content from '@/layout/Content.vue';
const DisplayCategoryList = (): Promise<typeof import('*.vue')> =>
  import('@/domain/category/display/views/DisplayCategoryList.vue');

const StandardCategoryList = (): Promise<typeof import('*.vue')> =>
  import('@/domain/category/standard/views/StandardCategoryList.vue');

export const categoryPathList = {
  path: '/category',
  name: 'CategoryContent',
  component: Content,
  meta: { title: '카테고리' },
  children: [
    {
      path: 'standard/list',
      name: 'standard category list',
      component: StandardCategoryList,
      meta: { title: '표준 카테고리' },
    },
    {
      path: 'display/list',
      name: 'display category list',
      component: DisplayCategoryList,
      meta: { title: '전시 카테고리' },
    },
  ],
};
