






import Vue from 'vue';
export default Vue.extend({
  props: {
    size: {
      type: String,
    },
  },
  computed: {
    spinnerSize(): string {
      if (this.size === 'sm') {
        return '1.5rem';
      } else if (this.size === 'lg') {
        return '6rem';
      } else {
        return '1rem';
      }
    },
    style(): string {
      return `font-size: ${this.spinnerSize}`;
    },
  },
});
