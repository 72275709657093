import { Term } from '@/common/Term';
import { Product } from '@/domain/product/model/product/response/product';

export interface ProductFilter {
  attributeIds?: string[] | null;
  exposedChannelIds?: string[] | null;
  hostId?: string | null;
  ids?: string[] | null;
  itemStatusIn?: string[] | null; // TODO: ItemState로 변경 필요
  kind?: ProductKind | null;
  managerId?: string | null;
  staffId?: string | null;
  standardCategoryIds?: string[] | null;
  statusIn?: ProductState[] | null;
  termWithType?: ProductTermWithTypeInput | null;
  titleLike?: string | null;
}

export enum ProductState {
  APPLIED = 'APPLIED',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  EDITING = 'EDITING',
  INSPECTING = 'INSPECTING',
  SALE = 'SALE',
  PAUSED = 'PAUSED',
  SUSPENDED = 'SUSPENDED',
  READY = 'READY',
  REJECTED = 'REJECTED',
  SOLD_OUT = 'SOLD_OUT',
  TERMINATED = 'TERMINATED',
}

export interface ProductTermWithTypeInput {
  type: ProductTermSearchType;
  term: Term;
}

export enum ProductTermSearchType {
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED',
  SALE_END = 'SALE_END',
  SALE_START = 'SALE_START',
}

export enum ProductKind {
  GOODS = 'GOODS',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  EVENT = 'EVENT',
}

export interface ProductConnection {
  edges: ProductEdge[];
  totalCount: number;
}

export interface ProductEdge {
  node: Product;
}
