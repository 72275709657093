import { ClientPlatform } from '@frientrip/domain';

interface PlatformOption {
  value: ClientPlatform;
  text: string;
}

export const platforms = [
  ClientPlatform.Web,
  ClientPlatform.Mobile,
  ClientPlatform.AndroidApp,
  ClientPlatform.iOSApp,
];

export const getPlatformSelectOptions = (): PlatformOption[] => {
  return [
    {
      value: ClientPlatform.Web,
      text: 'Web',
    },
    {
      value: ClientPlatform.Mobile,
      text: 'Mobile',
    },
    {
      value: ClientPlatform.AndroidApp,
      text: 'Android',
    },
    {
      value: ClientPlatform.iOSApp,
      text: 'iOS',
    },
  ];
};
