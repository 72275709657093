import { DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

export const minLimitMixin: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    el.addEventListener('input', e => {
      const min = binding.value;
      const target = e.target as HTMLInputElement; // TODO: 수정
      const value = target.value;
      if (Number(value) < min) {
        console.log(`${min}보다 작음`);
        target.value = min;
      }
    });
  },
};
