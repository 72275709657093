<template>
  <fade-transition>
    <b-alert
      :show="show"
      :variant="variant"
      @dismissed="dismissed"
      :dismissible="dismissible"
      role="alert"
    >
      <template v-if="icon || $slots.icon">
        <slot name="icon">
          <span class="alert-icon" data-notify="icon">
            <i :class="icon"></i>
          </span>
        </slot>
      </template>

      <span class="alert-text"> <slot></slot> </span>
    </b-alert>
  </fade-transition>
</template>
<script>
import { FadeTransition } from 'vue2-transitions';

export default {
  name: 'frip-alert',
  components: {
    FadeTransition,
  },
  props: {
    show: {
      type: Boolean || Number,
      default: 3,
      description:
        'Alert의 visibility를 결정, 숫자면 해당 (숫자)초 만큼 떠있다가 사라짐',
    },
    type: {
      type: String,
      default: 'default',
      description: 'Alert type',
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: 'Whether alert is dismissible (closeable)',
    },
    icon: {
      type: String,
      default: '',
      description: 'Alert icon to display',
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {
    variant() {
      return 'frip-' + this.type;
    },
  },
  methods: {
    dismissed() {
      this.$emit('dismissed', true);
    },
    dismissAlert() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss"></style>
