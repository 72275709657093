






































































































































import Vue from 'vue';
import { getLegacyApiUrl, isHostAdmin } from '@/env';
import { BaseNav } from '@/BaseComponents';
import MyInfo from '@/components/MyInfo.vue';
import axios from 'axios';
import { NOTICES } from '@/domain/notice/queries/query';
import {
  Notice,
  NoticeConnection,
  NoticeEdge,
  NoticeFilter,
} from '@/domain/notice/model/Notice';
import { ApolloError, ApolloQueryResult } from 'apollo-client';

interface NoticeRow {
  id: number;
  created: string;
  title: string;
  type: string;
  category?: string;
}

export default Vue.extend({
  name: 'DashBoardNavBar',
  components: {
    // CollapseTransition,
    BaseNav,
    MyInfo,
    // Modal,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      isHostAdmin: isHostAdmin(),
      notices: [] as NoticeRow[],
      currentIndex: 0,
    };
  },
  computed: {
    routeName(): string {
      const { name } = this.$route;
      if (name) {
        return this.capitalizeFirstLetter(name);
      } else {
        return '';
      }
    },
    navTitle(): string {
      return this.isHostAdmin ? '호스트 관리 페이지' : '어드민';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },
  apollo: {
    notices: {
      query: NOTICES,
      variables(): { filter: NoticeFilter; page: number; size: number } {
        return {
          filter: { forHostOnly: true },
          page: 1,
          size: 3,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ board: { notices: NoticeConnection } }>
      ) {
        this.$data.totalCount = result.data.board.notices.totalCount;
        this.$data.notices = result.data.board.notices.edges.map(
          (row: NoticeEdge) => {
            return row.node;
          }
        );

        this.$data.loading = false;
      },
      update: data => {
        console.log('HostAdminDashBoard data:', data);

        return data.board.notices;
      },
    },
  },
  methods: {
    logout(): void {
      // logout
      this.$store.dispatch('logout').then(() => this.$router.push('/login'));
    },
    capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown(): void {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown(): void {
      this.activeNotifications = false;
    },
    toggleSidebar(): void {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar(): void {
      this.$sidebar.displaySidebar(false);
    },
    detailHostNotice(row: Notice): void {
      const params = {
        id: row.id.toString(),
        title: row.title.toString(),
        content: row.content.toString(),
      };

      this.$router.push({
        name: 'HostNoticeDetail',
        params: params,
      });
    },
    changeShowNotice(direction: string): void {
      if (direction === 'up') {
        this.currentIndex =
          this.currentIndex + 1 > 2 ? 0 : this.currentIndex + 1;
      } else {
        this.currentIndex =
          this.currentIndex - 1 < 0 ? 2 : this.currentIndex - 1;
      }
    },
    goHostGuidePage(): void {
      window.open('https://frip.notion.site/94c1339b75e2437a9f6cff8e41c006e9');
    },
    goConciergeServicePage(): void {
      window.open(' https://bit.ly/HCS_magz');
    },
  },
});
