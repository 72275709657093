














import Vue from 'vue';
export default Vue.extend({
  name: 'frip-badge',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  computed: {
    bgColor() {
      return 'frip-' + this.type;
    },
    textColor() {
      return 'text-frip-' + this.type;
    },
  },
});
