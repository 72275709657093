import Content from '@/layout/Content.vue';

const SearchKeywordList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/keyword/views/SearchKeywordList.vue'
  );

export const keywordPathList = {
  path: '/keyword',
  name: 'Keyword',
  redirect: '/keyword/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'SearchKeywordList',
      component: SearchKeywordList,
      meta: { title: '홈 키워드 관리' },
    },
  ],
};
