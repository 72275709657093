<template>
  <validation-provider
    :rules="rules"
    :name="name"
    v-bind="$attrs"
    v-slot="{ errors, valid, invalid, validated }"
  >
    <b-form-group>
      <slot name="label">
        <label v-if="label" :class="labelClasses">
          {{ label }}
        </label>
      </slot>
      <div
        :class="[
          { 'input-group': hasIcon },
          { focused: focused },
          { 'input-group-alternative': alternative },
          { 'has-label': label || $slots.label },
          inputGroupClasses,
        ]"
      >
        <div v-if="prependText || $slots.prepend" class="input-group-prepend">
          <span class="input-group-text">
            <slot name="prepend">
              <small>{{ prependText }}</small>
            </slot>
          </span>
        </div>
        <slot v-bind="slotData">
          <input
            :value="value"
            :type="type"
            v-on="listeners"
            v-bind="$attrs"
            :valid="valid"
            :required="required"
            :disabled="disabled"
            class="form-control"
            :maxlength="maxlength"
            :class="[
              { 'is-valid': valid && validated && successMessage },
              { 'is-invalid': invalid && validated },
              inputClasses,
            ]"
            :style="{ width: width }"
          />
        </slot>
        <div v-if="appendText || $slots.append" class="input-group-append">
          <span
            :class="[
              'input-group-text',
              { 'append-disabled': disabled },
              { 'is-invalid': invalid && validated },
            ]"
          >
            <slot name="append">
              <small>{{ appendText }}</small>
            </slot>
          </span>
        </div>
        <slot name="infoBlock"></slot>
      </div>
      <slot name="success">
        <div class="valid-feedback" v-if="valid && validated && successMessage">
          {{ successMessage }}
        </div>
      </slot>
      <slot name="error">
        <div v-if="errors[0]" class="invalid-feedback" style="display: block">
          <!-- custom error message 추가 -->
          {{ invalidMessage !== '' ? invalidMessage : errors[0] }}
        </div>
      </slot>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  name: 'base-input',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    group: {
      type: Boolean,
      description:
        'Whether input is an input group (manual override in special cases)',
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: '',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    inputGroupClasses: {
      type: String,
      description: 'Input group css classes',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    type: {
      type: String,
      description: 'Input type',
      default: 'text',
    },
    appendText: {
      type: String,
      description: 'Append (right)',
    },
    prependText: {
      type: String,
      description: 'Prepend (left)',
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: '',
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      default: '',
    },
    invalidMessage: {
      type: String,
      description: 'Custom InValid Message',
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
    range: {
      type: Object,
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendText !== undefined ||
        this.prependText !== undefined ||
        this.group
      );
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit('input', value);
    },
    onChange(evt) {
      let value = evt.target.value;
      if (this.range && this.type === 'number' && value !== '') {
        value = Number(value);
        if (this.range.min > value) {
          value = this.range.min;
        } else if (this.range.max < value) {
          value = this.range.max;
        }
        this.$emit('input', value);
      }
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt);
    },
  },
};
</script>
<style scoped>
.input-group > .form-control:not(:last-child) {
  border-right: 0 !important;
}
.is-invalid {
  border-color: #de1c22 !important;
}
/* .append-disabled {
  background-color: #dddddd;
  opacity: 1;
} */
</style>
