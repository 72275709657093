export class WellknownLink {
  private static _instance: WellknownLink;

  public static get instance(): WellknownLink {
    if (!WellknownLink._instance) {
      WellknownLink._instance = new WellknownLink();
    }
    return WellknownLink._instance;
  }

  /**
   * 검수 상세 화면
   *
   * @param id 상품 ID
   * @returns 검수 상세화면 url
   */
  public inspection(id: string): string {
    return `/inspection/view/${id}`;
  }

  /**
   * 상품 수정 화면
   *
   * @param id 상품 ID
   * @returns 상품 수정화면 url
   */
  public productEdit(id: string): string {
    return `/product/list/${id}`;
  }

  /**
   * 예약 내역
   *
   * @param id 예약 ID
   * @returns 얘약 내역 url
   */
  public booking(id: string): string {
    return `/booking/view/${id}`;
  }

  /**
   * 구매 내역
   *
   * @param id 주문아이템 ID
   * @returns 구매 내역 url
   */
  public purchase(id: string): string {
    return `/purchase/view/${id}`;
  }

  /**
   * 주문 내역
   *
   * @param id 주문 ID
   * @returns 주문 내역 url
   */
  public order(id: string): string {
    return `/order/view/${id}`;
  }

  /**
   * 호스트 상세보기
   *
   * @param id 호스트 ID
   * @returns 호스트 상세보기 url
   */
  public host(id: string): string {
    return `/host/list/${id}`;
  }

  /**
   * 호스트 상품 모아보기
   *
   * @param hostId 호스트 ID
   * @returns 호스트 상품 리스트 url
   */
  public productByHost(hostId: string): string {
    return `/product/list?hostId=${hostId}`;
  }

  /**
   * 호스트 QnA 모아보기
   *
   * @param hostId 호스트 ID
   * @returns 호스트 상품 리스트 url
   */
  public qnaByHost(hostId: string): string {
    return `/qna/list?hostId=${hostId}`;
  }

  /**
   * 호스트 상품 후기 모아보기
   *
   * @param hostId 호스트 ID
   * @returns 호스트 리뷰 리스트 url
   */
  public reviewByHost(hostId: string): string {
    return `/product-review/list?hostId=${hostId}`;
  }
  /**
   * 상품 문의
   *
   * @param productId 상품 ID
   * @returns 상품 문의 url
   */
  public qnaByProduct(productId: string): string {
    return `/qna/list?productId=${productId}`;
  }

  /**
   * 상품 후기
   *
   * @param productId 상품 ID
   * @returns 상품 리뷰 url
   */
  public reviewByProduct(productId: string): string {
    return `/product-review/list?productId=${productId}`;
  }

  /**
   * 회원 상세보기
   *
   * @param id 회원 ID
   * @returns 회원 상세보기 url
   */
  public user(id: string): string {
    return `/user/list/${id}`;
  }

  /**
   * refId, redType에 따른 에너지 목록 보기
   *
   * @param refId 에너지 refId
   * @param refType
   * @returns 에너지 목록 url
   */
  public pointByReference(refId: string, refType: string): string {
    return `/points/list?refId=${refId}&refType=${refType}`;
  }

  /**
   * 알림톡 발송 내역 상세(호스트)
   * @param hostId 호스트 ID
   * @returns 알림톡 발송 내역 상세(호스트) URL
   */
  public notificationMessageByHost(hostId: string): string {
    return `/notificationMessage/list/host?hostId=${hostId}`;
  }

  /**
   * 정산서 내역
   * @param settlementId 정산서 ID
   * @returns 정산서 URL
   */
  public settlement(settlementId: string): string {
    return `/settlement/settlements/${settlementId}`;
  }

  /**
   * 지급서 내역
   * @param invoiceId 지급서 ID
   * @returns 지급서 URL
   */
  public invoice(invoiceId: string): string {
    return `/settlement/invoices/${invoiceId}`;
  }

  /**
   * 쿠폰 상세
   * @param couponId 쿠폰 ID
   * @returns 알림톡 발송 내역 상세(호스트) URL
   */
  public coupon(couponId: string): string {
    return `/coupon/list/${couponId}`;
  }
  /**
   * 상품별 예약 내역
   * @param productId ID
   * @returns 상품별 예약 내역 보기 URL
   */
  public bookingByProduct(productId: string): string {
    return `/booking/list?productIds=${productId}`;
  }
  /**
   * 일정별 예약 내역
   * @param scheduleId ID
   * @returns 일정별 예약 내역 보기 URL
   */
  public bookingBySchedule(scheduleId: string): string {
    return `/booking/schedule/${scheduleId}`;
  }
  /**
   * 아이템별 예약 내역
   * @param scheduleId ID
   * @returns 일정별 예약 내역 보기 URL
   */
  public bookingByItem(itemId: string): string {
    return `/booking/schedule/${itemId}`;
  }
  /**
   * 일정별 판매 내역
   * @param scheduleId ID
   * @returns 일정별 판매 내역 보기 URL
   */
  public purchaseBySchedule(scheduleId: string): string {
    return `/purchase/list?scheduleIds=${scheduleId}`;
  }
  /**
   * 상품별 일정 내역
   * @param productId ID
   * @returns 상품별 일정 보기 URL
   */
  public scheduleByProduct(productId: string): string {
    return `/product/list/${productId}/schedules`;
  }
  /**
   * 상품별 주문 내역
   * @param productId ID
   * @returns 일정별 판매 내역 보기 URL
   */
  public orderByProduct(productId: string): string {
    return `/order/transactions?productIds=${productId}`;
  }

  public curationGroup(curationGroupId: number): string {
    return `/curation/detail/${curationGroupId}`;
  }
}

interface VueDummy {
  prototype: Record<string, unknown>;
}

const WellknownLinkFunc = {
  install: (Vue: VueDummy): void => {
    Vue.prototype.$wlink = WellknownLink.instance;
  },
};

export default WellknownLinkFunc;
