
































import Vue from 'vue';
export default Vue.extend({
  props: {
    notification: {
      type: String,
    },
    notificationIcon: {
      type: String,
      default: 'ni ni-bulb-61',
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    headerClass: {
      type: String,
    },
    outline: {
      type: Boolean,
    },
    bodyPadding: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
    titleClass(): string {
      return this.isMobile ? 'text-sm' : '';
    },
  },
});
