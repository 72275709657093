import { _Record, PutRecordBatchCommand } from '@aws-sdk/client-firehose';
import { v4 as uuidv4 } from 'uuid';
import amplitude from 'amplitude-js';
import { getConstants, getRunningEnv } from '@/env';
import BaseKinesisEvent from './events/BaseKinesisEvent';
import firehoseClient from './firehoseClient';

const RECORD_INTERVAL = 1000 * 5;

function getDeviceId() {
  const currentDeviceId = localStorage.getItem('deviceId');

  if (currentDeviceId) {
    return currentDeviceId;
  }

  const newDeviceId = uuidv4();
  localStorage.setItem('deviceId', newDeviceId);
  return newDeviceId;
}

function getKinesisTextEncoder() {
  class PolyfillEncoder {
    encode(input: string): Uint8Array {
      const utf8 = unescape(encodeURIComponent(input));
      const result = new Uint8Array(utf8.length);
      for (let i = 0; i < utf8.length; i += 1) {
        result[i] = utf8.charCodeAt(i);
      }
      return result;
    }
  }

  return window.TextEncoder ? new TextEncoder() : new PolyfillEncoder();
}

let instance: AwsKinesis | null = null;

class AwsKinesis {
  private readonly textEncoder = getKinesisTextEncoder();
  private recordData: _Record[] = [];
  private session: {
    session_id: string;
    session_at: number;
    timer: NodeJS.Timeout;
  } | null = null;

  constructor() {
    if (instance) {
      return instance;
    }
    instance = this;
  }

  private getSessionValue() {
    if (this.session) {
      clearTimeout(this.session.timer);
      this.session.timer = setTimeout(() => {
        this.session = null;
      }, 30 * 60 * 1000);
      return this.session;
    }

    this.session = {
      session_id: uuidv4(),
      session_at: new Date().getTime(),
      timer: setTimeout(() => {
        this.session = null;
      }, 30 * 60 * 1000),
    };
    return this.session;
  }

  private makeDefaultProperties() {
    const session = this.getSessionValue();
    const parsedUserToken = JSON.parse(
      localStorage.getItem('userToken') || '{}'
    );

    return {
      device_id: getDeviceId(),
      session_id: session.session_id,
      session_at: session.session_at,
      user_id: parsedUserToken?.userId,
      host_id: parsedUserToken?.hostId,
      event_id: uuidv4(),
      platform: 'web',
      env: getRunningEnv(),
      device_brand: null,
      device_model: null,
      os_name: null,
      os_version: null,
      idfa: null,
      idfv: null,
      adid: null,
      language: 'korean',
      event_at: new Date().getTime(),
      amplitude_id: amplitude.getInstance().options.deviceId,
    };
  }

  private makeTrafficSource() {
    return {
      landing: window.location.href,
      document_location_path: window.location.pathname,
      referrer: document.referrer,
    };
  }

  private makeDataRecord(event: BaseKinesisEvent): _Record {
    return {
      Data: this.textEncoder.encode(
        JSON.stringify({
          default_properties: this.makeDefaultProperties(),
          traffic_source: this.makeTrafficSource(),
          user_agent: navigator.userAgent,
          event_properties: event,
        })
      ),
    };
  }

  private async uploadData() {
    try {
      await firehoseClient.send(
        new PutRecordBatchCommand({
          Records: this.recordData,
          DeliveryStreamName: getConstants().firehoseDeliveryStreamName,
        })
      );
      // TODO Delete test stream
      await firehoseClient.send(
        new PutRecordBatchCommand({
          Records: this.recordData,
          DeliveryStreamName: 'host-admin-test',
        })
      );
    } catch (e) {
      console.error(e);
    }
  }

  public async directPutRecord(event: BaseKinesisEvent): Promise<void> {
    try {
      await firehoseClient.send(
        new PutRecordBatchCommand({
          Records: [this.makeDataRecord(event)],
          DeliveryStreamName: getConstants().firehoseDeliveryStreamName,
        })
      );
    } catch (e) {
      console.error(e);
    }
  }

  public pushRecord(event: BaseKinesisEvent): void {
    this.recordData.push(this.makeDataRecord(event));
  }

  public initialize(): void {
    setInterval(async () => {
      if (!this.recordData.length) {
        return;
      }
      await this.uploadData();
      this.recordData = [];
    }, RECORD_INTERVAL);
  }
}

export default AwsKinesis;
