import Content from '@/layout/Content.vue';

const BundleList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "serial" */ '@/domain/serial/views/BundleList.vue'
  );
const BundleDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "serial" */ '@/domain/serial/views/BundleDetail.vue'
  );
const PointPolicyList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "serial" */ '@/domain/serial/views/PointPolicyList.vue'
  );
const PointPolicyDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "serial" */ '@/domain/serial/views/PointPolicyDetail.vue'
  );
const BundleSerialsListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "serial" */ '@/domain/serial/views/BundleSerialsListContainer.vue'
  );
export const serialPathList = {
  path: '/serial',
  name: 'Serial',
  redirect: '/serial/bundle/list',
  component: Content,
  children: [
    {
      path: 'bundle/list',
      name: 'SerialBundleList',
      component: BundleList,
    },
    {
      path: 'bundle/serials/:bundleId',
      name: 'BundleSerialsListContainer',
      component: BundleSerialsListContainer,
      meta: { title: '난수 목록' },
      props: true,
    },
    {
      path: 'bundle/create',
      name: 'SerialBundleCreate',
      component: BundleDetail,
    },
    {
      path: 'bundle/list/:id',
      name: 'SerialBundle',
      component: BundleDetail,
    },
    {
      path: 'policy/list',
      name: 'PointPolicyList',
      component: PointPolicyList,
    },
    {
      path: 'policy/create',
      name: 'PointPolicyCreate',
      component: PointPolicyDetail,
    },
    {
      path: 'policy/list/:id',
      name: 'PointPolicy',
      component: PointPolicyDetail,
    },
  ],
};
