import Content from '@/layout/Content.vue';

const HotKeywordList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "hotkeyword" */ '@/domain/hotkeyword/views/HotKeywordList.vue'
  );

export const hotKeywordPathList = {
  path: '/hotkeyword',
  name: 'HotKeyword',
  redirect: '/hotkeyword/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'HotKeywordList',
      component: HotKeywordList,
      meta: { title: '인기 검색어 관리' },
    },
  ],
};
