import { ApolloClient, DefaultOptions } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import {
  InMemoryCache,
  NormalizedCacheObject,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getApiUrl, getDataApiUrl } from './env';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';

const httpLink = new HttpLink({
  uri: getApiUrl(),
});
const dataHttpLink = new HttpLink({
  uri: getDataApiUrl(),
});

const authLink = setContext((_, { headers }) => {
  const accessToken = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions, message, locations, path }) => {
      if (extensions?.code === 'NOT_AUTHORIZED') {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userToken');
        location.reload();
      }
      console.error(
        `[GraphQL error]: extensions: ${JSON.stringify(
          extensions
        )}, Message: ${message}, Location: ${locations}, Path: ${path}, operationName: ${
          operation.operationName
        }`
      );
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'INTERFACE',
          name: 'HostIntroducer',
          possibleTypes: [
            {
              name: 'HostIntroducerByEtc',
            },
            {
              name: 'HostIntroducerBySaleManager',
            },
            {
              name: 'HostIntroducerDefault',
            },
          ],
        },
      ],
    },
  },
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

const defaultOptions: DefaultOptions = {
  // TODO: 일단은 cache-and-network가 없어서 이렇게 설정했지만, 캐싱이 동작하기는 한다.
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link,
    cache: cache,
    defaultOptions: defaultOptions,
  });
}

const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

const dataApolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(dataHttpLink)]),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export { apolloClient, dataApolloClient };
