






































































































































































































































































import Vue from 'vue';
import gql from 'graphql-tag';
import { Badge, Card, Row, Table, TableColumn, Tag } from 'element-ui';
import {
  CategoryType,
  Notice,
  NoticeConnection,
  NoticeEdge,
  NoticeFilter,
  NoticeType,
  NoticeTypeLabel,
} from '@/domain/notice/model/Notice';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ProductFilter, ProductState } from '@/domain/qna/model/product';
import Icon from '@/components/Icon/Icon.vue';
import filters from '@/util/filters';
import { Hooper, Pagination as HooperPagination, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import { NOTICES } from '@/domain/notice/queries/query';

interface BannerResponse {
  data: {
    banner: BannerData;
  };
}

interface BannerData {
  currents: BannersDataResult[];
}

interface BannersDataResult {
  id: string;
  actionUri: string;
  title: string;
  content: {
    thumbnail: string;
    __typename: string;
  };
}

interface DashboardDataResult {
  hostDashboard: {
    hostDashboard: {
      runningFrip?: number;
      monthlyCancel?: number;
      monthlyCancelByUser?: number;
      totalTurnover?: string;
      monthlyTurnover?: string;
      totalSaleCount?: number;
      answerRate?: string;
      reviewCount?: number;
      rating?: string;
    };
  };
}

const DASHBOARD_DATA_QUERY = gql`
  query DashboardData($hostId: ID!) {
    hostDashboard {
      hostDashboard(hostId: $hostId) {
        runningFrip
        monthlyCancel
        monthlyCancelByUser
        totalTurnover
        monthlyTurnover
        totalSaleCount
        answerRate
        reviewCount
        rating
      }
    }
  }
`;

export default Vue.extend({
  components: {
    [Badge.name]: Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Row.name]: Row,
    Icon,
    Hooper,
    Slide,
    HooperPagination,
  },

  data(): {
    me: {
      host: {
        name: string;
        super: boolean;
      };
    };
    notices: [];
    noticeType: CategoryType;
    noticeTypeLabel: { label: string; type: string }[];
    dashboardData: DashboardDataResult;
    banners: [];
  } {
    return {
      me: {
        host: {
          name: '',
          super: false,
        },
      },
      notices: [],
      noticeType: NoticeType,
      noticeTypeLabel: NoticeTypeLabel,
      banners: [],
      dashboardData: {
        hostDashboard: {
          hostDashboard: {
            runningFrip: 0,
            monthlyCancel: 0,
            monthlyCancelByUser: 0,
            totalTurnover: '0.00',
            monthlyTurnover: '0.00',
            totalSaleCount: 0,
            answerRate: '0.00',
            reviewCount: 0,
            rating: '0.00',
          },
        },
      },
    };
  },
  computed: {
    bodyClass(): string {
      return this.isMobile ? 'py-1' : 'p-2';
    },
    isMobile(): boolean {
      return this.$store.state.isMobile;
    },
  },

  methods: {
    openHostManual() {
      window.open('https://frip.notion.site/94c1339b75e2437a9f6cff8e41c006e9');
    },
    detailHostNotice(row: Notice): void {
      const params = {
        id: row.id.toString(),
        title: row.title.toString(),
        content: row.content.toString(),
      };

      this.$router.push({
        name: 'HostNoticeDetail',
        params: params,
      });
    },
    goHostGuidePage(): void {
      window.open('https://frip.notion.site/94c1339b75e2437a9f6cff8e41c006e9');
    },
    goKakaoChannel(): void {
      window.open('http://goto.kakao.com/@friphost');
    },
    openUrl(url: string) {
      window.open(url, '_blank');
    },
    checkedType(type: string): string {
      switch (type) {
        case 'HOST_NOTICE':
          return 'primary';

        case 'HOST_EVENT':
          return 'success';

        default:
          return 'danger';
      }
    },
  },
  apollo: {
    me: {
      query: gql`
        query me {
          me {
            host {
              id
              name
              super
            }
          }
        }
      `,
      error(e: ApolloError) {
        console.error(e);
      },
    },

    banners: {
      query: gql`
        query GetCurrentBanners($platform: ClientPlatform) {
          banner {
            currents(slotId: "host_admin", platform: $platform) {
              id
              actionUri
              title
              content {
                thumbnail(fetchFormat: AUTO)
                __typename
              }
            }
          }
        }
      `,
      variables(): { platform: string } {
        return {
          platform: this.isMobile ? 'Mobile' : 'Web',
        };
      },
      update: data => {
        const bannerResponse: BannerData = data.banner;
        const bannersData: BannersDataResult[] = bannerResponse.currents;
        console.log('bannersData = ', bannersData);
        return bannersData;
      },
    },
    notices: {
      query: NOTICES,
      variables(): { filter: NoticeFilter; page: number; size: number } {
        return {
          filter: { forHostOnly: true },
          page: 1,
          size: 5,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ board: { notices: NoticeConnection } }>
      ) {
        this.$data.totalCount = result.data.board.notices.totalCount;
        this.$data.notices = result.data.board.notices.edges.map(
          (row: NoticeEdge) => {
            return row.node;
          }
        );

        this.$data.loading = false;
      },
      update: data => {
        console.log('HostAdminDashBoard data:', data);

        return data.board.notices;
      },
    },
    hostInfoRequired: {
      query: gql`
        query me {
          me {
            host {
              id

              # 프로필사진
              profileImage {
                id
              }
              personalInfo {
                phoneNumber # 휴대폰인증
                email # 이메일
              }
              name # 호스트명
              phoneNumber # 공개연락처
              description # 소개
              settlement {
                bankAccount {
                  account {
                    # 입금계좌 - 은행
                    bank {
                      code
                      name
                    }
                    holder # 입금계좌 - 예금주
                    accountNo # 입금계좌 - 계좌번호
                  }
                }
                # 세금계산서 발급 대상
                businessInfo {
                  taxPayerName
                  taxPayerType
                }
                # 신분증
                attachments {
                  id
                  fileKey
                  status
                }
              }
            }
          }
        }
      `,
      error(e: ApolloError) {
        console.error(e);
      },
      fetchPolicy: 'no-cache',
      update: data => {
        return !(
          data.me.host.profileImage?.id.trim() &&
          data.me.host.personalInfo?.phoneNumber.trim() &&
          data.me.host.personalInfo?.email.trim() &&
          data.me.host.name.trim() &&
          data.me.host.phoneNumber.trim() &&
          data.me.host.description?.trim()
        );
      },
    },
    productRequired: {
      variables(): {
        filter: ProductFilter;
      } {
        return {
          filter: {
            statusIn: [
              ProductState.EDITING,
              ProductState.APPLIED,
              ProductState.INSPECTING,
              ProductState.REJECTED,
              ProductState.READY,
              ProductState.SALE,
              ProductState.SOLD_OUT,
              ProductState.PAUSED,
              ProductState.CLOSED,
              ProductState.TERMINATED,
              ProductState.DELETED,
            ],
          },
        };
      },
      query: gql`
        query products($filter: ProductFilterInput) {
          product {
            products(filter: $filter) {
              totalCount
            }
          }
        }
      `,
      update: data => {
        return data.product?.products?.totalCount == 0 || false;
      },
    },
    dashboardData: {
      query: DASHBOARD_DATA_QUERY,
      variables(): { hostId: string } {
        return {
          hostId: this.$store.state.userToken.hostId,
        };
      },
      update({
        hostDashboard: {
          hostDashboard: {
            runningFrip,
            monthlyCancel,
            monthlyCancelByUser,
            totalTurnover,
            monthlyTurnover,
            totalSaleCount,
            answerRate,
            reviewCount,
            rating,
          },
        },
      }: DashboardDataResult) {
        return {
          hostDashboard: {
            hostDashboard: {
              runningFrip: filters.formatNumber(runningFrip || 0),
              monthlyCancel: filters.formatNumber(monthlyCancel || 0),
              monthlyCancelByUser: filters.formatNumber(
                monthlyCancelByUser || 0
              ),
              totalTurnover: filters.formatNumber(totalTurnover || '0'),
              monthlyTurnover: filters.formatNumber(monthlyTurnover || '0'),
              totalSaleCount: filters.formatNumber(totalSaleCount || 0),
              answerRate: answerRate || '0.00',
              reviewCount: filters.formatNumber(reviewCount || 0),
              rating: rating || '0.00',
            },
          },
        };
      },
    },
  },
});
