import Content from '@/layout/Content.vue';

const FaqDetail = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faqs/views/FaqDetail.vue');

const HostFaqContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faqs/views/HostFaqContainer.vue');

const HostFaqDetail = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faqs/views/HostFaqDetail.vue');

const FaqList = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faqs/views/FaqList.vue');

export const faqsPathList = {
  path: '/faqs',
  name: 'FaqContent',
  redirect: '/faqs/list/service',
  component: Content,
  children: [
    {
      path: '/faqs/list/:faqType',
      name: 'FaqList',
      component: FaqList,
      meta: { title: '자주 묻는 질문 관리' },
    },
    {
      path: '/faqs/detail/:faqType',
      name: 'FaqDetail',
      component: FaqDetail,
      meta: { title: '자주 묻는 질문 상세' },
      props: true,
    },
    {
      path: '/faqs',
      name: 'HostFaqContainer',
      component: HostFaqContainer,
      meta: { title: '자주 묻는 질문' },
    },
    {
      path: '/faqs/:id',
      name: 'HostFaqDetail',
      component: HostFaqDetail,
      meta: { title: '자주 묻는 질문 상세' },
      props: true,
    },
  ],
};
