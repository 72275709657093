

















import Vue from 'vue';
export default Vue.extend({
  name: 'InlineText',
  props: {
    label: {
      type: String,
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    contentColsLg: {
      type: String,
      default: '10',
    },
  },
});
