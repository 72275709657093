import { DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

export const maxLimitMixin: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    el.addEventListener('input', e => {
      const max = binding.value;
      const target = e.target as HTMLInputElement; // TODO: 수정
      const value = target.value;
      if (Number(value) > max) {
        console.log(`${max}보다 큼`);
        target.value = max;
      }
    });
  },
};
