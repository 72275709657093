






































import Vue from 'vue';
import { isHostAdmin } from '@/env';
import { BaseNav } from '@/BaseComponents';

export default Vue.extend({
  components: {
    // CollapseTransition,
    BaseNav,
    // Modal,
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      isHostAdmin: isHostAdmin(),
      notices: ['공지사항'],
    };
  },
  computed: {
    routeName(): string {
      const { name } = this.$route;
      if (name) {
        return this.capitalizeFirstLetter(name);
      } else {
        return '';
      }
    },
    navTitle(): string {
      return this.isHostAdmin ? '호스트 어드민' : '어드민';
    },
  },
  methods: {
    capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
});
