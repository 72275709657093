

































import Vue from 'vue';
export default Vue.extend({
  name: 'InputRow',
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    contentColsLg: {
      type: String,
      default: '10',
    },
  },
});
