/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BaseInput from '@/BaseComponents/Inputs/BaseInput.vue';
import BaseDropdown from '@/BaseComponents/BaseDropdown.vue';
import Card from '@/BaseComponents/Cards/Card.vue';
import Modal from '@/BaseComponents/Modal.vue';
import StatsCard from '@/BaseComponents/Cards/StatsCard.vue';
import BaseButton from '@/BaseComponents/BaseButton.vue';
import Badge from '@/BaseComponents/Badge.vue';
import RouteBreadcrumb from '@/BaseComponents/Breadcrumb/RouteBreadcrumb.vue';
import BaseCheckbox from '@/BaseComponents/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/BaseComponents/BaseSwitch.vue';
import BaseRadio from '@/BaseComponents/Inputs/BaseRadio.vue';
import BaseProgress from '@/BaseComponents/BaseProgress.vue';
import BasePagination from '@/BaseComponents/BasePagination.vue';
import BaseAlert from '@/BaseComponents/BaseAlert.vue';
import BaseNav from '@/BaseComponents/Navbar/BaseNav.vue';
import BaseHeader from '@/BaseComponents/BaseHeader.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover } from 'element-ui';
import BaseValidationInput from '@/BaseComponents/Inputs/BaseValidationInput.vue';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

export const GlobalComponents = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseValidationInput.name, BaseValidationInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component(Input.name, Input);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.use(Tooltip);
    Vue.use(Popover);
  },
};
