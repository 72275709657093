import Content from '@/layout/Content.vue';

const DeliveryListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "delivery" */ '@/domain/delivery/views/DeliveryListContainer.vue'
  );

export const deliveryPathList = {
  path: '/delivery',
  name: 'DeliveryContent',
  redirect: '/delivery/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'DeliveryListContainer',
      component: DeliveryListContainer,
      meta: { title: '배송 관리' },
    },
  ],
};
