<template>
  <div class="wrapper">
    <notifications></notifications>
    <frip-alert-modal-container></frip-alert-modal-container>
    <side-bar :sidebar-links="menus" logo="/img/frip.png"> </side-bar>
    <div class="main-content mb-9">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
    </div>

    <host-admin-footer
      v-if="isHostAdmin && $route.meta.showFooter"
    ></host-admin-footer>
    <content-footer
      v-if="!isHostAdmin && !$route.meta.hideFooter"
    ></content-footer>
  </div>
</template>
<script>
/* eslint-disable no-new */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import { FadeTransition } from 'vue2-transitions';
import { getMyAllMenus } from '@/router/menu';
import { isHostAdmin } from '../env';
import HostAdminFooter from '../layout/HostAdminFooter.vue';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    // DashboardContent,
    FadeTransition,
    HostAdminFooter,
  },
  data() {
    return {
      menus: [],
      excludeFullPath: ['/qna/list', '/notices'],
      isHostAdmin: isHostAdmin(),
    };
  },
  async created() {
    this.menus = await getMyAllMenus();
  },
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
  },
};
</script>
<style lang="scss">
.mb-9 {
  margin-bottom: 9rem;
}
</style>
