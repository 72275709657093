import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { FirehoseClient } from '@aws-sdk/client-firehose';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

const REGION = 'ap-northeast-2';
const COGNITO_IDENTITY_POOL_ID =
  'ap-northeast-2:707f7430-deab-47e4-90c3-47a18fc1439b';

const firehoseClient = new FirehoseClient({
  region: REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: REGION }),
    identityPoolId: COGNITO_IDENTITY_POOL_ID,
  }),
});

export default firehoseClient;
