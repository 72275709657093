import { apolloClient } from '@/apolloClient';
import gql from 'graphql-tag';
import { getRunningEnv, RUNNING_ENV, isHostAdmin } from '../env';
import store from '@/store';

export interface Menu {
  name: string;
  // svgIcon: string;
  permissionIds: string[];
  children: Menu[];
  envLevel: string[];
  path: string;
  state?: MenuState;
}

enum MenuState {
  HOST,
  ADMIN,
  BOTH,
  NONE,
}

interface Permission {
  id: string;
  items: PermissionItem[];
}

interface PermissionItem {
  type: string;
  field: string;
}

const isLegacyHost = () => {
  //수정이 필요함
  const maxLegacyHostId = 17060;
  return Number(store.state.userToken?.hostId) <= maxLegacyHostId;
};

const envLevel = {
  dev: [RUNNING_ENV.DEVELOPMENT],

  staging: [RUNNING_ENV.DEVELOPMENT, RUNNING_ENV.STAGING],
  prod: [RUNNING_ENV.DEVELOPMENT, RUNNING_ENV.STAGING, RUNNING_ENV.PRODUCTION],
};
//추후 permissionIds 추가해야함
const allMenus = [
  {
    name: '내 정보',
    icon: 'iconInfo',
    path: '/host',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '프로필 관리',
        path: '/host/profile',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.HOST,
      },
      {
        name: '정산정보 관리',
        path: '/host/account',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.HOST,
      },
    ],
    state: MenuState.HOST,
  },
  {
    name: '회원 관리',
    icon: 'iconPeople',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '크루 관리',
        path: '/user/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '호스트 관리',
        path: '/',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '호스트 목록',
            path: '/host/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '슈퍼 호스트 일괄 등록',
            path: '/host/super/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '호스트 거래처 정보 목록',
            path: '/host/business-info/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: '프립 관리',
    path: '/product',
    icon: 'iconLogo',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '프립 관리',
        path: '/product',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '프립 목록',
            path: '/product/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '프립 등록',
            path: '/product/create',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '프립 검수',
            path: '/inspection',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
      {
        name: '프립 목록',
        path: '/product/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.HOST,
        children: [],
      },
      {
        name: '프립 등록',
        path: '/product/create',
        envLevel: envLevel.prod,
        state: MenuState.HOST,
        permissionIds: [],
        children: [],
      },
      {
        name: '판매 관리',
        path: '/purchase/list',
        envLevel: envLevel.prod,
        state: MenuState.HOST,
        permissionIds: [],
        children: [],
      },
      {
        name: '예약 관리',
        path: '/booking',
        envLevel: envLevel.prod,
        state: MenuState.HOST,
        permissionIds: [],
        children: [],
      },
      {
        name: '배송 관리',
        path: '/delivery',
        envLevel: envLevel.prod,
        state: MenuState.HOST,
        permissionIds: [],
        children: [],
      },
      {
        name: '문의 관리',
        path: '/qna/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.HOST,
      },
      {
        name: '후기 관리',
        path: '/product-review/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.HOST,
      },
      {
        name: '태그 관리',
        path: '/product/tags',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '전시지역 관리',
        path: '/area/display/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: 'MD 관리',
        path: '/product/merchandiser/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '판매 관리',
    icon: 'iconStorefront',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '판매 관리',
        path: '/purchase/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '예약 관리',
        path: '/booking',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '배송 관리',
        path: '/delivery',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '주문 관리',
    icon: 'iconCart',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '주문 관리',
        path: '/order/transactions',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.ADMIN,
      },
      {
        name: '환불 관리',
        path: '/refund/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
        state: MenuState.ADMIN,
      },
    ],
    state: MenuState.ADMIN,
  },
  {
    name: '정산관리',
    icon: 'iconBill',
    path: '/settlement',
    envLevel: envLevel.prod,
    permissionIds: [],
    state: MenuState.BOTH,
    children: [
      {
        name: '정산 계좌',
        path: '/settlement/accounts',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.BOTH,
        children: [],
      },
      {
        name: '정산서 관리',
        path: '/settlement/settlements',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.BOTH,
        children: [],
      },
      {
        name: '지급서 관리',
        path: '/settlement/invoices',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.BOTH,
        children: [],
      },
      {
        name: '세금계산서 목록',
        path: '/settlement/taxInvoices',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.ADMIN,
        children: [],
      },
    ],
  },
  {
    name: '공지사항',
    icon: 'iconArticle',
    path: '/notice/list/host',
    envLevel: envLevel.prod,
    permissionIds: [],
    state: MenuState.HOST,
    children: [
      {
        name: '공지사항',
        path: '/notices',
        envLevel: envLevel.prod,
        permissionIds: [],
        state: MenuState.HOST,
        children: [],
      },
    ],
  },
  {
    name: '콘텐츠 관리',
    icon: 'iconContent',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '문의 관리',
        path: '/qna',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '프립별 문의 관리',
            path: '/qna/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '서비스문의 관리',
            path: '/qna/service/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
      {
        name: '후기 관리 ',
        path: '/product-review/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '피드 관리 ',
        path: '/social-feed/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '매거진 관리',
    icon: 'iconContacts',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '매거진 관리',
        path: '/magazine/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '마케팅 관리',
    icon: 'iconCampaign',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '배너 관리',
        path: '/banner/slot/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '홈 키워드 관리',
        path: '/keyword/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '홈 USP 관리',
        path: '/usp/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '홈 숏컷 관리',
        path: '/shortcut/shortcutset/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '홈 기획전 관리',
        path: '/',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '상품 기획전 관리',
            path: '/collection/product/slots',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '테마 기획전 관리',
            path: '/collection/theme/slots',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
      {
        name: '인기 검색어 관리',
        path: '/hotkeyword/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '초기화면 노출팝업',
        path: '/introPopup/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '큐레이션 관리',
        path: '/curationGroup/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '카테고리',
    icon: 'iconCategory',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '표준 카테고리',
        path: '/category/standard/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '전시 카테고리',
        path: '/category/display/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '에너지 & 쿠폰',
    icon: 'iconConfirmation',
    path: '/',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '에너지 관리',
        path: '/points/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '에너지 수동 지급',
        path: '/points/give',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '에너지 수동 지급 내역',
        path: '/points/give/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '에너지 일련번호 정책',
        path: '/serial/policy/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '쿠폰 관리',
        path: '/coupon/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '발급된 쿠폰 목록',
        path: '/coupon/list/issued/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '기획전/매거진 쿠폰 관리',
        path: '/coupon/download-coupon/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '일련번호 생성',
        path: '/serial/bundle/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '고객센터관리',
    icon: 'iconHeadset',
    path: '/notice',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [
      {
        name: '공지사항 관리',
        path: '/notice/list/service',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '호스트 공지사항 관리',
        path: '/notice/list/host',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '자주 묻는 질문',
        path: '/faq/list',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '[신규] 자주 묻는 질문 관리',
        path: '/faqs/list/service',
        envLevel: envLevel.dev,
        permissionIds: [],
        children: [],
      },
      {
        name: '[개발서버] 호스트 자주 묻는 질문 관리',
        path: '/faqs/list/host',
        envLevel: envLevel.dev,
        permissionIds: [],
        children: [],
      },

      {
        name: '알림톡 관리',
        path: '/notificationMessage',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '크루 발송내역',
            path: '/notificationMessage/list/user',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '호스트 발송내역',
            path: '/notificationMessage/list/host',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
    ],
  },
  {
    name: '지역관리',
    envLevel: envLevel.prod,
    permissionIds: [],
    children: [],
  },
  {
    name: '서비스 관리',
    icon: 'iconSmartphone',
    envLevel: envLevel.prod,
    path: '/',
    permissionIds: [],
    children: [
      {
        name: '앱 버전 관리',
        path: '/metadata/app',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
      {
        name: '약관 및 정책 관리',
        path: '/metadata/term',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [],
      },
    ],
  },
  {
    name: '관리자',
    path: '/',
    envLevel: envLevel.prod,
    icon: 'iconManage',
    permissionIds: [],
    children: [
      {
        name: '계정 관리',
        path: '/',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '계정 목록',
            path: '/manager/account/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '계정 요청',
            path: '/manager/account/request',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '계정 추가',
            path: '/manager/account/add',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
      {
        name: '역할 관리',
        path: '/',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '역할 목록',
            path: '/manager/role/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '역할 추가',
            path: '/manager/role/add',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
      {
        name: '권한 관리',
        path: '/',
        envLevel: envLevel.prod,
        permissionIds: [],
        children: [
          {
            name: '권한 목록',
            path: '/manager/permission/list',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
          {
            name: '권한 추가',
            path: '/manager/permission/add',
            envLevel: envLevel.prod,
            permissionIds: [],
            children: [],
          },
        ],
      },
    ],
  },
];

const getMyAllPermissions = async () => {
  const myAllPermissions = await apolloClient.query({
    query: gql`
      query authority {
        authority {
          myAllPermissions {
            id
            items {
              type
              field
            }
          }
        }
      }
    `,
  });
  const permissions = myAllPermissions.data.authority.myAllPermissions;
  return permissions;
};

const remakeMenu = (
  menu: Menu,
  permissionIds: string[],
  env: string
): Menu | null => {
  if (menu.state === MenuState.NONE) {
    return null;
  }
  if (menu.children.length > 0) {
    const newChildren = [];
    for (const i in menu.children) {
      const newMenu = remakeMenu(menu.children[i], permissionIds, env);
      if (newMenu) newChildren.push(newMenu);
    }
    if (newChildren.length > 0) {
      menu.children = newChildren;
      return menu;
    }
    return null;
  }
  if (isHostAdmin()) {
    if (menu.state === MenuState.HOST || menu.state === MenuState.BOTH) {
      return menu;
    }
    return null;
  }
  if (menu.state === MenuState.HOST) {
    return null;
  }

  const isVisibleMenu =
    (menu.permissionIds.some(id => permissionIds.includes(id)) ||
      menu.permissionIds.length === 0) &&
    (menu.envLevel?.includes(env) || // local, next, development에서는 체크하지 않는다.
      env === RUNNING_ENV.LOCAL ||
      env === RUNNING_ENV.DEVELOPMENT);

  if (isVisibleMenu) {
    return menu;
  }
  return null;
};

export async function getMyAllMenus(): Promise<Menu[]> {
  let permissions = [];
  const env = getRunningEnv();

  if (env === RUNNING_ENV.DEVELOPMENT && !isHostAdmin()) {
    // 당분간은 개발환경에서만 권한체크하도록 한다.
    permissions = await getMyAllPermissions();
  }

  const permissionIds = permissions.map(
    (permission: Permission) => permission.id
  );
  //가장 하위 메뉴의 permission을 확인
  const menus = (allMenus as Menu[])
    .map(menu => remakeMenu(menu, permissionIds, env))
    .filter(menu => menu != null) as Menu[];
  return menus;
}
