import ProductContent from './layout/ProductContent.vue';

const ProductListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Product/ProductListContainer.vue'
  );

const ProductSaveContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Product/ProductSaveContainer.vue'
  );

const ProductTag = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Tag/ProductTag.vue'
  );
const ProductMerchandiserList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Merchandiser/MerchandiserList.vue'
  );

const ProductMerchandiserDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Merchandiser/MerchandiserDetail.vue'
  );

const ProductScheduleList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "product" */ '@/domain/product/views/Product/ProductScheduleList.vue'
  );

export const productPathList = {
  path: '/product',
  name: 'ProductContent',
  redirect: '/product/list',
  component: ProductContent,
  children: [
    {
      path: 'list',
      name: 'ProductSearchContainer',
      component: ProductListContainer,
      meta: { title: '프립 목록' },
    },
    {
      path: 'list/:id',
      name: 'ProductUpdateContainer',
      component: ProductSaveContainer,
      meta: { title: '프립 수정' },
    },
    {
      path: 'list/:id/schedules',
      name: 'ProductScheduleList',
      component: ProductScheduleList,
      meta: { title: '프립 일정' },
    },
    {
      path: 'create',
      name: 'ProductCreateContainer',
      component: ProductSaveContainer,
      meta: { title: '프립 등록' },
    },
    {
      path: 'tags',
      name: 'ProductTag',
      component: ProductTag,
      meta: { title: '프립 태그 관리' },
    },
    {
      path: 'merchandiser/list',
      name: 'ProductMerchandiserList',
      component: ProductMerchandiserList,
      meta: { title: '프립 MD 관리' },
    },
    {
      path: 'merchandiser/list/:id',
      name: 'ProductMerchandiserDetail',
      component: ProductMerchandiserDetail,
      meta: { title: '프립 MD 관리' },
    },
    {
      path: 'merchandiser/create',
      name: 'ProductMerchandiserCreate',
      component: ProductMerchandiserDetail,
      meta: { title: '프립 MD 관리' },
    },
  ],
};
