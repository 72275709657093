import Content from '@/layout/Content.vue';

const ServiceQnaList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/qna/views/ServiceQnAList.vue'
  );
const ProductQnaList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/qna/views/ProductQnaList.vue'
  );
const ProductQnaDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/qna/views/ProductQnaDetail.vue'
  );

export const qnaPathList = {
  path: '/qna',
  name: 'QnaContent',
  redirect: '/qna/list',
  component: Content,
  children: [
    {
      path: '/qna/list',
      name: 'ProductQnaList',
      component: ProductQnaList,
      meta: { title: '문의관리' },
    },
    {
      // user 상세에서 사용될 조회 링크
      path: '/qna/service/list',
      name: 'ServiceQnaList',
      component: ServiceQnaList,
      meta: { title: '문의' },
    },
    {
      path: '/qna/detail/:id',
      name: 'ProductQnaDetail',
      component: ProductQnaDetail,
      meta: { title: '문의답변' },
    },
  ],
};
