import Content from '@/layout/Content.vue';
import { CollectionType } from './model/slot';

const CollectionSlotList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionSlotList" */ '@/domain/collection/views/CollectionSlotList.vue'
  );

const CollectionSlotForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionSlotForm" */ '@/domain/collection/views/CollectionSlotForm.vue'
  );

const CollectionProductList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionProductList" */ '@/domain/collection/views/CollectionProductList.vue'
  );

const CollectionProductForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionProductForm" */ '@/domain/collection/views/CollectionProductForm.vue'
  );

const CollectionThemeList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionThemeList" */ '@/domain/collection/views/CollectionThemeList.vue'
  );

const CollectionThemeForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "collectionThemeForm" */ '@/domain/collection/views/CollectionThemeForm.vue'
  );

export const collectionPathList = {
  path: '/collection',
  name: 'Collection',
  redirect: '/collection/product/slots',
  component: Content,
  children: [
    {
      path: 'product/slots',
      name: 'CollectionProductSlotList',
      component: CollectionSlotList,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 관리' },
    },
    {
      path: 'product/create',
      name: 'CollectionProductSlotCreate',
      component: CollectionSlotForm,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 슬롯 생성' },
    },
    {
      path: 'product/slots/:slotId',
      name: 'CollectionProductSlotUpdate',
      component: CollectionSlotForm,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 슬롯 수정' },
    },
    {
      path: 'product/slots/:slotId/collections',
      name: 'CollectionProductList',
      component: CollectionProductList,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 리스트' },
    },
    {
      path: 'product/slots/:slotId/collections/:collectionId',
      name: 'CollectionProductUpdate',
      component: CollectionProductForm,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 수정' },
    },
    {
      path: 'product/slots/:slotId/create',
      name: 'CollectionProductCreate',
      component: CollectionProductForm,
      props: { CollectionType: CollectionType.PRODUCT },
      meta: { title: '홈 상품 기획전 생성' },
    },
    {
      path: 'theme/slots',
      name: 'CollectionThemeSlotList',
      component: CollectionSlotList,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 관리' },
    },
    {
      path: 'theme/create',
      name: 'CollectionThemeSlotCreate',
      component: CollectionSlotForm,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 슬롯 생성' },
    },
    {
      path: 'theme/slots/:slotId',
      name: 'CollectionThemeSlotUpdate',
      component: CollectionSlotForm,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 슬롯 수정' },
    },
    {
      path: 'theme/slots/:slotId/collections',
      name: 'CollectionThemeList',
      component: CollectionThemeList,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 리스트' },
    },
    {
      path: 'theme/slots/:slotId/collections/:collectionId',
      name: 'CollectionThemeUpdate',
      component: CollectionThemeForm,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 수정' },
    },
    {
      path: 'theme/slots/:slotId/create',
      name: 'CollectionThemeCreate',
      component: CollectionThemeForm,
      props: { CollectionType: CollectionType.THEME },
      meta: { title: '홈 테마 기획전 생성' },
    },
  ],
};
