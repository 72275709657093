













import Vue from 'vue';
import { isHostAdmin } from '@/env';
import { imageUploadService } from '@/components/ImageUpload/imageUploadService';

export default Vue.extend({
  name: 'FroalaEditor',
  props: {
    config: {
      type: Object,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      content: this.value,
      editorConfig: {
        key: isHostAdmin()
          ? 'qc1H2pD1A3A2C7E6A5orgJ-7gB3quhhaG3C2A5D5C4E3F3D4G2I2=='
          : 'VCC6kD3G3G3I3B8A7byrhrH3gjD7lzuF2C1G1F1A10C1C6A1A5F5==',
        attribution: false,
        language: 'ko',
        fontFamily: {
          "'Noto Sans Korean',sans-serif": '본고딕',
          "'Noto Serif Korean',sans-serif": '본명조',
          "'Nanum Gothic',sans-serif": '나눔고딕',
          "'Nanum Myeongjo',sans-serif": '나눔명조',
          "'Nanum Pen Script',sans-serif": '나눔손글씨 펜',
          "'Nanum Brush Script',sans-serif": '나눔손글씨 붓',
          "'Nanum Gothic Coding',sans-serif": '나눔고딕 코딩',
        },
        fontFamilySelection: true,
        fontSize: ['12', '14', '16', '18', '20', '22', '24', '26', '28', '30'],
        fontSizeDefaultSelection: '18',
        placeholderText: this.config?.placeholderText
          ? this.config.placeholderText
          : '호스트님의 상품을 소개해주세요 :)',
        requestWithCORS: false,
        // 사용될수도 있으므로 주석 처리함
        toolbarButtons: [
          'fontFamily',
          'fontSize',
          '|',
          // 'fullscreen',
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          '|',
          // 'paragraphFormat',
          // 'paragraphStyle',
          // 'subscript',
          // 'superscript',
          'inlineStyle',
          'textColor',
          'backgroundColor',
          'clearFormatting',
          '|',
          'lineHeight',
          'insertHR',
          // 'inlineClass',
          '|',
          'align',
          'formatOL',
          'formatUL',
          'quote',
          '-',
          'insertImage',
          'insertVideo',
          'insertLink',
          'embedly',
          'insertTable',
          // '|',
          // 'selectAll',
          // 'undo',
          // 'redo',
          // 'specialCharacters',
        ],
        toolbarButtonsSM: [
          'fullscreen',
          'bold',
          'italic',
          'underline',
          // 'fontSize',
          'insertLink',
          'insertImage',
          'insertTable',
          'undo',
          'redo',
        ],
        toolbarButtonsXS: [
          'bold',
          'italic',
          // 'fontSize',
          'undo',
          'redo',
        ],

        colorsHEXInput: false,
        imageEditButtons: [
          'imageReplace',
          'imageRemove',
          '-',
          'imageLink',
          // 'imageDisplay',
          // 'imageStyle',
          'imageAlt',
          'imageSize',
        ],
        imageDefaultWidth: 0,
        editorClass: 'froala-editor',
        inlineStyles: {
          타이틀:
            'font-weight: bold; font-size: 24px; line-height: 33px; letter-spacing: -0.5px;',
          '서브 타이틀':
            'font-weight: bold; font-size: 18px; line-height: 22px; letter-spacing: -0.5px;',
          본문: 'font-weight: normal; font-size: 18px; line-height: 28px; letter-spacing: -0.5px;',
          캡션: 'font-weight: normal; font-size: 13px; line-height: 18px; letter-spacing: -0.5px;',
        },

        videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
        videoAllowedTypes: [],

        imageUploadURL:
          'https://api.cloudinary.com/v1_1/frientrip/image/upload',
        imageUploadParams: {
          upload_preset: 'ir9wmcc1',
        },
        events: {
          initialized() {
            // (this as any).edit.off();
          },

          'image.beforeUpload': async (images: FileList): Promise<void> => {
            console.log('image before upload');
            const uploadInfo = await imageUploadService.getUploadInfo({
              fileName: images[0].name,
            });

            // this.$data.imageUploadURL = uploadInfo.uploadUrl;

            const form = imageUploadService.makeFormForUpload(
              images[0],
              uploadInfo
            );
            this.$emit('upload', uploadInfo.contentId);

            this.$data.imageUploadParams = form;
            this.$data.contentId = uploadInfo.contentId;
          },
          'image.uploaded'(response: string): boolean {
            const img = JSON.parse(response);
            const img_url = img.secure_url;
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const editor: any = this;
            editor.image.insert(
              img_url,
              false,
              null,
              editor.image.get(),
              response
            );
            return false;
          },
          'image.inserted': () => {
            console.log('inserted');
          },
          'image.error': (e: Error) => {
            console.error(e);
          },
        },

        // height: this.config?.height ? this.config?.height : 100,
        heightMin: 200,
        // heightMax: 2000,
      },
    };
  },
  watch: {
    value(newValue: string) {
      this.content = newValue;
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.content);
    },
  },
});
