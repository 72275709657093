import Content from '@/layout/Content.vue';

const BookingListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "booking" */ '@/domain/booking/views/BookingListContainer.vue'
  );

const BookingViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "booking" */ '@/domain/booking/views/BookingViewContainer.vue'
  );

const BookingScheduleViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "booking" */ '@/domain/booking/views/BookingScheduleViewContainer.vue'
  );

export const bookingPathList = {
  path: '/booking',
  name: 'BookingContent',
  redirect: '/booking/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'BookingListContainer',
      component: BookingListContainer,
      meta: { title: '예약 관리' },
    },
    {
      path: 'view/:id',
      name: 'BookingViewContainer',
      component: BookingViewContainer,
      meta: { title: '예약 내역' },
    },
    {
      path: 'schedule/:id',
      name: 'BookingScheduleViewContainer',
      component: BookingScheduleViewContainer,
      meta: { title: '일정별 예약내역' },
    },
  ],
};
