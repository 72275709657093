import Content from '@/layout/Content.vue';

const InspectionListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "inspection" */ '@/domain/inspection/views/InspectionListContainer.vue'
  );

const InspectionViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "inspection" */ '@/domain/inspection/views/InspectionViewContainer.vue'
  );

export const inspectionPathList = {
  path: '/inspection',
  name: 'InspectionContent',
  redirect: '/inspection/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'InspectionListContainer',
      component: InspectionListContainer,
      meta: { title: '검수' },
    },
    {
      path: 'view/:id',
      name: 'InspectionViewContainer',
      component: InspectionViewContainer,
      meta: { title: '검수' },
    },
  ],
};
