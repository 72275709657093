import Content from '@/layout/Content.vue';

const AppVersion = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "mobile" */ '@/domain/mobile/views/AppVersionForm.vue'
  );
const FripTerm = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "mobile" */ '@/domain/mobile/views/FripTerm.vue');

export const mobilePathList = {
  path: '/metadata',
  name: 'Metadata',
  redirect: 'app',
  component: Content,
  children: [
    {
      path: 'app',
      name: 'App Version management',
      component: AppVersion,
      children: [],
    },
    {
      path: 'term',
      name: 'Frip term management',
      component: FripTerm,
      children: [],
    },
  ],
};
