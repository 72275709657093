export const RUNNING_ENV = {
  LOCAL: 'local',
  DEVELOPMENT: 'dev',
  // NEXT: 'next',
  // BETA: 'beta',
  STAGING: 'staging',
  PRODUCTION: 'prod',
} as const;

import { CONSTANTS, Constants } from './constants';

type RUNNING_ENV = typeof RUNNING_ENV[keyof typeof RUNNING_ENV];

const FripRegEx = /^https:\/\/admin-v3-?([\w]*).frip.co.kr/;

const HostRegEx = /^https:\/\/([\w]*).?frip.host/;

export function getRunningEnv(): RUNNING_ENV {
  const url = window.location.href;

  const matchOfFrip = FripRegEx.exec(url);

  if (matchOfFrip) {
    return convertEnvByName(matchOfFrip[1]);
  }

  const matchOfHost = HostRegEx.exec(url);

  if (matchOfHost) {
    return convertEnvByName(matchOfHost[1]);
  }

  if (/:(3000|4000)/.test(url)) {
    return RUNNING_ENV.LOCAL;
  }

  return RUNNING_ENV.DEVELOPMENT;
}

function convertEnvByName(name: string): RUNNING_ENV {
  let env: RUNNING_ENV = RUNNING_ENV.DEVELOPMENT;

  switch (name) {
    case RUNNING_ENV.STAGING:
      env = RUNNING_ENV.STAGING;
      break;
    case '':
      env = RUNNING_ENV.PRODUCTION;
      break;
    case 'www':
      env = RUNNING_ENV.PRODUCTION;
      break;
  }

  return env;
}

function getLocalServicePrefix() {
  return RUNNING_ENV.DEVELOPMENT;
}

function makeServicePrefix(env: RUNNING_ENV): string {
  let prefix = '';
  console.log(env);
  switch (env) {
    case RUNNING_ENV.LOCAL:
      prefix = getLocalServicePrefix();
      break;
    case RUNNING_ENV.DEVELOPMENT:
      prefix = RUNNING_ENV.DEVELOPMENT;
      break;
    case RUNNING_ENV.STAGING:
      prefix = RUNNING_ENV.STAGING;
      break;
  }

  if (prefix) {
    prefix = '-' + prefix;
  }

  return prefix;
}

export function getApiUrl(): string {
  const prefix = makeServicePrefix(getRunningEnv());
  return `https://gql${prefix}.frip.co.kr/graphql`;
}

export function getLegacyApiUrl(): string {
  let prefix = '';
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.LOCAL:
    case RUNNING_ENV.DEVELOPMENT:
      prefix = 'beta-';
      break;
    case RUNNING_ENV.STAGING:
      prefix = 'staging-';
      break;
  }
  return `https://${prefix}api.frientrip.com`;
}

export function getAuthUrl(): string {
  const prefix = makeServicePrefix(getRunningEnv());
  return `https://identity${prefix}.frip.co.kr`;
}

export function getDataApiUrl(): string {
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.PRODUCTION:
    case RUNNING_ENV.STAGING:
      return 'https://data.internal.frip.co.kr/graphql';
    default:
      return 'https://data-dev.internal.frip.co.kr/graphql';
  }
}

export function isHostAdmin(): boolean {
  const url = window.location.href;
  const matchOfFrip = FripRegEx.exec(url);

  if (matchOfFrip) {
    return false;
  }

  return !/:(3000)/.test(url);
}

export function getLegacyAdminUrl(): string {
  let prefix = '';
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.LOCAL:
    case RUNNING_ENV.DEVELOPMENT:
      prefix = 'beta-';
      break;
    case RUNNING_ENV.STAGING:
      prefix = RUNNING_ENV.STAGING;
      prefix = 'staging-';
      break;
  }
  return `https://${prefix}admin.frip.co.kr`;
}
export function getLegacyHostUrl(): string {
  let prefix = '';
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.LOCAL:
    case RUNNING_ENV.DEVELOPMENT:
      prefix = RUNNING_ENV.DEVELOPMENT;
      prefix = 'beta-';
      break;
    case RUNNING_ENV.STAGING:
      prefix = RUNNING_ENV.STAGING;
      prefix = 'staging-';
      break;
  }

  return `https://${prefix}host.frip.co.kr`;
}
export function getConstants(): Constants {
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.LOCAL:
    case RUNNING_ENV.DEVELOPMENT:
      return CONSTANTS.dev;
    case RUNNING_ENV.STAGING:
      return CONSTANTS.staging;
    case RUNNING_ENV.PRODUCTION:
      return CONSTANTS.prod;
  }
}

export function getDomainName(): string {
  let url = '';
  const env = getRunningEnv();
  switch (env) {
    case RUNNING_ENV.LOCAL:
    case RUNNING_ENV.DEVELOPMENT:
      url = 'https://dev.frip.co.kr';
      break;
    case RUNNING_ENV.STAGING:
      url = 'https://staging.frip.co.kr';
      break;
    case RUNNING_ENV.PRODUCTION:
      url = 'https://frip.co.kr';
      break;
  }
  return url;
}

export function getPageTitle(): string {
  if (isHostAdmin()) {
    return '프립 호스트 어드민';
  }

  const env = getRunningEnv();
  const envTitle = env === RUNNING_ENV.PRODUCTION ? '' : `-${env}`;

  return `frip${envTitle}-admin`;
}
