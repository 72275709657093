const initializedModal = {
  title: '',
  message: '',
  type: 'default',
  showCancelButton: false,
  showAlertIcon: true,
  icon: '',
  confirmText: '확인',
  confirmButtonType: '',
  cancelText: '취소',
  cancelButtonClass: '',
  cancelButtonType: 'secondary',
  showClose: true,
  modalName: '',
  modalClasses: '',
  size: '',
  modalContentClasses: '',
  headerClasses: '',
  bodyClassess: '',
  footerClasses: '',
  animationDuration: 500,
  allowOutSideClick: false,
  html: '',
};

const FripAlertModalStore = {
  modal: JSON.parse(JSON.stringify(initializedModal)),
  onConfirm: {},
  onCancel: {},
  visible: false,
  initModal() {
    this.modal.title = initializedModal.title;
    this.modal.message = initializedModal.message;
    this.modal.type = initializedModal.type;
    this.modal.showCancelButton = initializedModal.showCancelButton;
    this.modal.showAlertIcon = initializedModal.showAlertIcon;
    this.modal.icon = initializedModal.icon;
    this.modal.confirmText = initializedModal.confirmText;
    this.modal.confirmButtonType = initializedModal.confirmButtonType;
    this.modal.cancelText = initializedModal.cancelText;
    this.modal.cancelButtonClass = initializedModal.cancelButtonClass;
    this.modal.cancelButtonType = initializedModal.cancelButtonType;
    this.modal.showClose = initializedModal.showClose;
    this.modal.modalName = initializedModal.modalName;
    this.modal.modalClasses = initializedModal.modalClasses;
    this.modal.size = initializedModal.size;
    this.modal.modalContentClasses = initializedModal.modalContentClasses;
    this.modal.headerClasses = initializedModal.headerClasses;
    this.modal.bodyClassess = initializedModal.bodyClassess;
    this.modal.footerClasses = initializedModal.footerClasses;
    this.modal.animationDuration = initializedModal.animationDuration;
    this.modal.allowOutSideClick = initializedModal.allowOutSideClick;
    this.onConfirm = {};
    this.onCancel = {};
    this.modal.html = initializedModal.html;
  },
  addModal(modalOption, onConfirm, onCancel) {
    this.initModal();

    Object.keys(this.modal).forEach(key => {
      if (modalOption[key]) {
        this.modal[key] = modalOption[key];
      }
    });

    this.onConfirm = onConfirm;
    this.onCancel = onCancel;
    this.visible = true;
  },
  removeModal() {
    this.visible = false;
  },
};

export const FripAlertPlugin = {
  install(Vue) {
    const app = new Vue({
      data: {
        fripAlertModalStore: FripAlertModalStore,
      },
      methods: {
        show(modalOption, onConfirm, onCancel) {
          console.log(modalOption);
          this.fripAlertModalStore.addModal(modalOption, onConfirm, onCancel);
        },
      },
    });
    Vue.prototype.$modal = { show: app.show };
    Vue.prototype.$modalStore = app.fripAlertModalStore;
  },
};

export default FripAlertPlugin;
