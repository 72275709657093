import { SocialLinkProviderType } from '@/domain/user/models/user';

export interface LoginParam {
  email: string;
  password: string;
  byUser?: boolean;
}
export interface SignUpParam {
  email: string;
  password: string;
  nickname: string;
  mobileNumber: string;
  verificationKey: string;
  agreement: SignUpAgreementParam;
  store?: string;
}
export interface SocialLoginParam {
  provider: SocialLinkProviderType;
  token: string;
  byUser?: boolean;
}
export interface SocialSignUpParam extends SocialLoginParam {
  email: string;
  nickname: string;
  mobileNumber: string;
  verificationKey?: string;
  agreement: SignUpAgreementParam;
}
export interface SignUpAgreementParam {
  marketing: boolean;
}

export interface EmailUserRegistParam extends LoginParam {
  userId?: string;
  phoneNumber?: string;
  isNewbie: boolean;
}
export interface SocialUserRegistParam extends SocialLoginParam {
  userId?: string;
  phoneNumber?: string;
  email?: string;
  isNewbie: boolean;
  agreement: SignUpAgreementParam;
  nickname?: string;
}
export interface VerificationForm {
  mobileNumber: string;
  verificationKey: string;
}
export enum HostLoginState {
  //유저,호스트 계정 모두 있을 때
  HOST = 'HOST',
  //유저 계정만 있을 때
  USERONLY = 'USERONLY',
  //둘다 없을 때
  NONE = 'NONE',
}

export class EmailUserByLogin implements EmailUserRegistParam {
  email: string;
  password: string;
  isNewbie: boolean;
  userId?: string;
  phoneNumber?: string;

  constructor(
    email: string,
    password: string,
    isNewbie: boolean,
    userId?: string,
    phoneNumber?: string
  ) {
    this.email = email;
    this.password = password;
    this.isNewbie = isNewbie;
    this.userId = userId;
    this.phoneNumber = phoneNumber;
  }
}
export class SocialUserByLogin implements SocialUserRegistParam {
  email?: string;
  provider: SocialLinkProviderType;
  token: string;
  userId?: string;
  phoneNumber?: string;
  isNewbie: boolean;
  agreement: SignUpAgreementParam;
  nickname?: string;

  constructor(
    provider: SocialLinkProviderType,
    token: string,
    isNewbie: boolean,
    userId?: string,
    phoneNumber?: string,
    email?: string,
    marketing?: boolean,
    nickname?: string
  ) {
    this.email = email;
    this.provider = provider;
    this.token = token;
    this.isNewbie = isNewbie;
    this.userId = userId;
    this.phoneNumber = phoneNumber;
    this.agreement = { marketing: marketing || false };
    this.nickname = nickname;
  }
}
