




















































































































import Vue from 'vue';
import moment from 'moment';
import { INTRO_POPUP } from '@/domain/introPopup/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import {
  IntroPopup,
  IntroPopupContentType,
  IntroPopupParam,
  IntroPopupUrl,
} from '@/domain/introPopup/model/introPopup';
import FroalaEditor from '@/components/FroalaEditor.vue';
import ImageUploadBox from '@/components/ImageUpload/ImageUploadBox.vue';
import { IntroPopupService } from '@/domain/introPopup/service/IntroPopupService';
import { apolloClient } from '@/apolloClient';
import { ImageUploadService } from '@/components/ImageUpload/imageUploadService';
import { platforms } from '@/common/platforms';
import { ClientPlatform } from '@frientrip/domain';
import Spinner from '@/components/Spinner.vue';

const introPopupService = new IntroPopupService(apolloClient);
const imageUploadService = new ImageUploadService(apolloClient);

export default Vue.extend({
  name: 'IntroPopupDetail',
  components: {
    Spinner,
    ImageUploadBox,
    FroalaEditor,
  },
  data(): {
    loading: false;
    exposureList: [
      { text: string; value: boolean },
      { text: string; value: boolean }
    ];
    id: number;
    introPopup: IntroPopup;
    popupType: IntroPopupContentType;
    contentTypeList: [
      { text: string; value: string },
      { text: string; value: string }
    ];
    newContentId: number;
    platforms: ClientPlatform[];
    startedAt: string;
    endedAt: string;
  } {
    return {
      loading: false,
      exposureList: [
        { text: '노출', value: true },
        { text: '비노출', value: false },
      ],
      id: Number(this.$route.params.id),
      introPopup: {
        id: 0,
        title: '',
        urls: [
          {
            id: '0',
            platform: ClientPlatform.AndroidApp,
            url: '',
          },
          {
            id: '0',
            platform: ClientPlatform.Mobile,
            url: '',
          },
          {
            id: '0',
            platform: ClientPlatform.Web,
            url: '',
          },
          {
            id: '0',
            platform: ClientPlatform.iOSApp,
            url: '',
          },
        ],
        content: '',
        startedAt: this.$moment().startOf('D').toDate(),
        endedAt: this.$moment().startOf('D').toDate(),
        exposed: true,
        url: '',
        contentType: IntroPopupContentType.IMAGE,
        indexNo: 0,
        image: {
          id: 0,
          uri: '',
        },
      },
      popupType: IntroPopupContentType.IMAGE,
      contentTypeList: [
        {
          text: '이미지',
          value: IntroPopupContentType.IMAGE,
        },
        {
          text: '텍스트',
          value: IntroPopupContentType.TEXT,
        },
      ],
      newContentId: 0,
      platforms: platforms,
      startedAt: '',
      endedAt: '',
    };
  },
  methods: {
    async modifyIntroPopup(): Promise<void | boolean> {
      this.$data.introPopup.urls.map((introPopupUrl: any) => {
        delete introPopupUrl['__typename'];
      });

      (this as any).setExposeDate();
      const param: IntroPopupParam = {
        id: this.$data.introPopup.id,
        title: this.$data.introPopup.title,
        contentId: this.$data.introPopup.image
          ? this.$data.introPopup.image.id
          : null,
        content: this.$data.introPopup.content
          ? this.$data.introPopup.content
          : null,
        startedAt: this.$data.introPopup.startedAt,
        endedAt: this.$data.introPopup.endedAt,
        exposed: this.$data.introPopup.exposed,
        urls: this.$data.introPopup.urls,
        contentType: this.$data.introPopup.contentType,
      };

      this.$modal.show(
        {
          title: '수정',
          message: '수정을 진행 하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await introPopupService.updateIntroPopup(param);

            //이미지 수정일 경우는 finalize를 하기위해서
            if (this.$data.newContentId !== 0) {
              const contentIdsParam: string[] = [this.$data.newContentId];
              await imageUploadService.finalizeContents(contentIdsParam);
            }

            this.$modal.show(
              {
                title: '수정 완료',
                message: '수정 완료 되었습니다.',
                type: 'success',
              },
              () => {
                (this as any).introPopupList();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '수정 실패',
              message: '수정에 실패했습니다.',
              type: 'danger',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    introPopupList(): void {
      this.$router.push('/introPopup/list');
    },
    updateContentId(newContentId: number): void {
      this.$data.newContentId = newContentId;
    },
    registerIntroPopup(): void | boolean {
      (this as any).setExposeDate();

      if (!(this as any).validationRegister()) {
        return;
      }

      this.$modal.show(
        {
          title: '팝업 저장',
          message: '팝업을 저장하시겠습니까?',
          type: 'info',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            const param: IntroPopupParam = {
              id: this.$data.introPopup.id,
              title: this.$data.introPopup.title,
              contentId: this.$data.introPopup.image.id
                ? this.$data.introPopup.image.id
                : null,
              content: this.$data.introPopup.content,
              startedAt: this.$data.introPopup.startedAt,
              endedAt: this.$data.introPopup.endedAt,
              exposed: this.$data.introPopup.exposed,
              urls: this.$data.introPopup.urls,
              indexNo: 1,
              contentType: this.$data.introPopup.contentType,
            };

            await introPopupService.registerIntroPopup(param);

            this.$modal.show(
              {
                title: '등록 완료',
                message: '등록 완료 되었습니다.',
                type: 'success',
              },
              () => {
                (this as any).introPopupList();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '저장 실패!',
              message: '',
              type: 'warning',
            });
          }
        }
      );
    },
    validationRegister(): boolean {
      const TITLE_MESSAGE = '저장 실패!';

      if (!this.$data.introPopup.startedAt) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '노출 시작시간을 입력해주세요.',
          type: 'warning',
        });
        (this as any).$refs.startedAt.focus();

        return false;
      }

      if (!this.$data.introPopup.endedAt) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '노출 종료시간을 입력해주세요.',
          type: 'warning',
        });
        (this as any).$refs.endedAt.focus();

        return false;
      }

      if (this.$data.introPopup.startedAt >= this.$data.introPopup.endedAt) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '노출 시작시간이 노출 종료시간보다 같거나 클 수 없습니다.',
          type: 'warning',
        });
        (this as any).$refs.startedAt.focus();

        return false;
      }

      if (!this.$data.introPopup.title) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '제목을 입력해주세요.',
          type: 'warning',
        });
        (this as any).$refs.title.focus();

        return false;
      }

      const introPopupUrl = this.$data.introPopup.urls.find(
        (url: IntroPopupUrl) => {
          if (url.url) {
            return url;
          }
        }
      );

      if (!introPopupUrl) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '딥링크를 최소 하나 이상 등록해주세요.',
          type: 'warning',
        });
        (this as any).$refs.deeplink.focus();

        return false;
      }

      if (
        this.$data.introPopup.contentType === IntroPopupContentType.IMAGE &&
        !this.$data.newContentId
      ) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '이미지를 등록해주세요!',
          type: 'warning',
        });

        return false;
      }

      if (
        this.$data.introPopup.contentType === IntroPopupContentType.TEXT &&
        !this.$data.introPopup.content
      ) {
        this.$modal.show({
          title: TITLE_MESSAGE,
          message: '텍스트를 입력해주세요!',
          type: 'warning',
        });

        return false;
      }

      return true;
    },
    deleteIntroPopup(): void {
      const id = this.$data.introPopup.id;

      this.$modal.show(
        {
          title: '팝업 삭제!',
          message: '팝업 삭제를 진행하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await introPopupService.deleteIntroPopup(id);

            this.$modal.show(
              {
                title: '팝업 삭제 성공!',
                message: '팝업 삭제를 완료했습니다.',
                type: 'success',
              },
              () => {
                (this as any).introPopupList();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '팝업 삭제 실패!',
              message: '팝업 삭제를 실패했습니다.',
              type: 'warning',
            });
          }
        },
        () => {
          return;
        }
      );
    },
    changeStartedAt(date: string): void {
      this.$data.startedAt = new Date(date);
    },
    changeEndedAt(date: string): void {
      this.$data.endedAt = new Date(date);
    },
    showIntroPopupExposeDate(date: Date): string {
      return this.$moment(date).format('YYYY-MM-DDTHH:mm:ss');
    },
    setExposeDate(): void {
      console.log(this.$data.startedAt);
      if (this.$data.startedAt) {
        this.$data.introPopup.startedAt = this.$data.startedAt;
      }

      if (this.$data.endedAt) {
        this.$data.introPopup.endedAt = this.$data.endedAt;
      }
    },
  },
  apollo: {
    introPopup: {
      query: INTRO_POPUP,
      variables() {
        return { id: this.$data.id };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          board: { introPopup: IntroPopup };
        }>
      ) {
        this.$data.introPopup = result.data.board.introPopup;
        this.$data.loading = true;
      },
      update: data => data.board.introPopup,
      skip(): boolean {
        if (!this.$route.params.id) {
          this.$data.loading = true;
          return true;
        }

        return false;
      },
    },
  },
});
