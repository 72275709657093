















import Vue from 'vue';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { PROFILE_CONTENT_FRAGMENT } from '@/common/contentConstants';
import { isHostAdmin } from '@/env';

export default Vue.extend({
  data() {
    return {
      loading: 0,
      me: {
        id: '',
        user: {
          id: '',
          nickname: '',
          profileImage: {
            thumbnail: '',
          },
        },
        host: {
          id: '',
          name: '',
          profileImage: {
            thumbnail: '',
          },
        },
      },
    };
  },
  computed: {
    profileImage(): string {
      return isHostAdmin()
        ? this.me.host.profileImage?.thumbnail || ''
        : this.me.user.profileImage?.thumbnail || '';
    },
    name(): string {
      return isHostAdmin()
        ? this.me.host.name || ''
        : this.me.user.nickname || '';
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/login'));
    },
  },
  apollo: {
    me: {
      query: gql`
        ${PROFILE_CONTENT_FRAGMENT}
        query me {
          me {
            user {
              id
              nickname
              profileImage {
                ...thumbnailFields
              }
            }
            host {
              id
              name
              profileImage {
                ...thumbnailFields
              }
            }
          }
        }
      `,
      error(e: ApolloError) {
        console.error(e);
      },
      result(result) {
        // TODO: 개선 필요
        if (isHostAdmin() && !result.data.me.host) {
          this.logout();
        }
      },
    },
  },
});
