import gql from 'graphql-tag';
import { PROFILE_CONTENT_FRAGMENT } from '@/common/contentConstants';

export const UPDATE_INTRO_POPUP = gql`
  mutation ($param: IntroPopupParam!) {
    updateIntroPopup(param: $param) {
      id
      title
      image {
        __typename
        ...thumbnailFields
        uri
      }
      urls {
        id
        url
        platform
      }
      url
      content
      startedAt
      endedAt
      exposed
      contentType
      indexNo
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;

export const REGISTER_INTRO_POPUP = gql`
  mutation ($param: IntroPopupParam!) {
    createIntroPopup(param: $param) {
      id
      title
      image {
        __typename
        ...thumbnailFields
        uri
      }
      urls {
        id
        url
        platform
      }
      url
      content
      startedAt
      endedAt
      exposed
      contentType
      indexNo
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;

export const CHANGE_INTRO_POPUP_INDEX_NUMBER = gql`
  mutation ($param: [IntroPopupChangedIndexNoParam!]!) {
    changeIntroPopupsIndexNo(param: $param) {
      success
      message
    }
  }
`;

export const DELETE_INTRO_POPUP = gql`
  mutation ($id: ID!) {
    deleteIntroPopup(id: $id) {
      success
      message
    }
  }
`;

export const EXPOSE_INTRO_POPUP = gql`
  mutation ($param: IntroPopupExposureParam!) {
    exposeIntroPopup(param: $param) {
      success
      message
    }
  }
`;
