import gql from 'graphql-tag';

export const NOTICES = gql`
  query ($filter: NoticeFilter, $page: Int, $size: Int) {
    board {
      notices(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            title
            content
            createdAt
            type
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const NOTICE_CATEGORY_LIST = gql`
  query ($param: NoticeTypeParam!) {
    board {
      noticeTypes(param: $param) {
        label
        value
      }
    }
  }
`;

export const NOTICE = gql`
  query ($filter: NoticeFilter, $page: Int, $size: Int) {
    board {
      notices(filter: $filter, page: $page, size: $size) {
        edges {
          node {
            id
            title
            content
            createdAt
            type
          }
        }
      }
    }
  }
`;
