import BaseKinesisEvent from './BaseKinesisEvent';

class BannerClickEvent extends BaseKinesisEvent {
  constructor(
    private readonly banner_location: string,
    private readonly image_url: string
  ) {
    super('banner_click');
  }
}

export default BannerClickEvent;
