import { isHostAdmin } from '@/env';
import BasicLayout from '@/layout/BasicLayout.vue';
import VueRouter, { Route } from 'vue-router';
import { SocialUserByLogin, EmailUserByLogin } from './model/identity';

const Login = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/admin/views/Login.vue'
  );
const App = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/common/views/LoginContainer.vue'
  );

const HostLogin = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/host/views/Login.vue'
  );
const HostSignUp = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/host/views/SignUp.vue'
  );

const PasswordResetRequest = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/common/views/PasswordResetRequest.vue'
  );
const SignUpContainer = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/host/views/SignUpContainer.vue'
  );
const HostRegistCompleted = () =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/login/host/views/HostRegistCompleted.vue'
  );

// const PasswordResetRequestSuccess = () =>
//   import(
//     /* webpackChunkName: "banner" */ '@/domain/login/common/views/PasswordResetRequestSuccess.vue'
//   );
export const passwordResetPathList = {
  path: '/resetPassword',
  name: 'resetPassword',
  component: BasicLayout,
  redirect: '/resetPassword/request',
  children: [
    {
      path: '/resetPassword/request',
      name: 'PasswordResetRequest',
      meta: {
        requireAuth: false,
      },
      component: PasswordResetRequest,
    },
  ],
};
export const signUpPathList = {
  path: '/regist',
  name: 'regist',
  component: BasicLayout,
  meta: {
    requireAuth: false,
  },
  redirect: '/regist',
  children: [
    {
      path: '',
      name: 'registHome',
      component: HostSignUp,
    },
    {
      path: 'form',
      name: 'registForm',
      component: SignUpContainer,
      props: (route: Route) => ({
        socialUserByLogin: convertToSocialUserByLogin(route.params.param),
        emailUserByLogin: convertToEmailUserByLogin(route.params.param),
      }),
    },
    {
      path: 'completed',
      name: 'HostRegistCompleted',
      component: HostRegistCompleted,
      props: (route: Route) => ({
        socialUser: convertToSocialUserByLogin(route.params.param),
        emailUser: convertToEmailUserByLogin(route.params.param),
      }),
    },
  ],
};

export const convertToEmailUserByLogin = (
  param: any
): EmailUserByLogin | null => {
  try {
    const parsed: EmailUserByLogin = JSON.parse(param);
    if (parsed.password) {
      const EmailUserRegistParam: EmailUserByLogin = new EmailUserByLogin(
        parsed.email,
        parsed.password,
        parsed.isNewbie,
        parsed.userId,
        parsed.phoneNumber
      );
      return EmailUserRegistParam;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const convertToSocialUserByLogin = (
  param: any
): SocialUserByLogin | null => {
  console.log(param);
  try {
    const parsed: SocialUserByLogin = JSON.parse(param);
    if (parsed.token) {
      const socialUserRegistParam: SocialUserByLogin = new SocialUserByLogin(
        parsed.provider,
        parsed.token,
        parsed.isNewbie,
        parsed.userId,
        parsed.phoneNumber,
        parsed.email,
        parsed.agreement.marketing,
        parsed.nickname
      );
      return socialUserRegistParam;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const loginPathList = {
  path: '/login',
  meta: {
    requireAuth: false,
  },
  component: App,
  children: [
    {
      path: '/',
      name: 'login',
      meta: {
        requireAuth: false,
      },
      component: isHostAdmin() ? HostLogin : Login,
    },
  ],
};
