































import Vue from 'vue';
import FripAlertModal from './FripAlertModal.vue';

interface ModalOption {
  title?: string;
  message?: string;
  type?: string;
  showCancelButton?: boolean;
  showAlertIcon?: boolean;
  icon?: string;
  confirmText?: string;
  confirmButtonType?: string;
  cancelText?: string;
  cancelButtonClass?: string;
  showClose?: boolean;
  modalName?: string;
  modalClasses?: string;
  size?: string;
  modalContentClasses?: string;
  headerClasses?: string;
  bodyClasses?: string;
  footerClasses?: string;
  animationDuration?: number;
  allowOutSideClick?: boolean;
  html?: string;
}

export default Vue.extend({
  name: 'FripAlertModalContainer',
  components: {
    FripAlertModal,
  },

  data() {
    return {};
  },
  computed: {
    show: {
      get(): boolean {
        return this.$modalStore.visible;
      },
      set(newValue: boolean): void {
        this.$modalStore.visible = newValue;
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modal(): ModalOption {
      return this.$modalStore.modal;
    },
  },
  methods: {
    // TODO: 함수가 아닌 다른 타입을 넣었을때 에러나는 로직 추가 필요
    confirmModal() {
      if (
        this.$modalStore.onConfirm &&
        this.$modalStore.onConfirm instanceof Function
      ) {
        this.$modalStore.onConfirm();
      }
    },
    cancelModal() {
      if (
        this.$modalStore.onCancel &&
        this.$modalStore.onCancel instanceof Function
      ) {
        this.$modalStore.onCancel();
      }
    },
    closeModal(): void {
      this.$modalStore.removeModal();
    },
  },
});
