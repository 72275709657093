import Content from '@/layout/Content.vue';

const USPList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "usp" */ '@/domain/usp/views/USPList.vue');

export const uspPathList = {
  path: '/usp',
  name: 'USP',
  redirect: '/usp/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'USPList',
      component: USPList,
      meta: { title: '홈 USP 관리' },
    },
  ],
};
