import gql from 'graphql-tag';

export const PROFILE_CONTENT_FRAGMENT = gql`
  fragment thumbnailFields on Content {
    id
    thumbnail(
      fetchFormat: AUTO
      crop: FILL
      aspectRatio: "1:1"
      width: 150
      height: 150
    )
  }
`;
