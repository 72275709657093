import gql from 'graphql-tag';
import { PROFILE_CONTENT_FRAGMENT } from '@/common/contentConstants';

export const INTRO_POPUPS = gql`
  query ($filter: IntroPopupFilter, $page: Int, $size: Int) {
    board {
      introPopups(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            title
            image {
              __typename
              ...thumbnailFields
            }
            urls {
              id
              url
              platform
            }
            url
            content
            startedAt
            endedAt
            exposed
            contentType
            indexNo
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;

export const EXPOSURED_INTRO_POPUPS = gql`
  query {
    board {
      exposuredIntroPopups {
        id
        title
        image {
          __typename
          ...thumbnailFields
        }
        urls {
          id
          url
          platform
        }
        url
        content
        startedAt
        endedAt
        exposed
        contentType
        indexNo
      }
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;

export const INTRO_POPUP = gql`
  query ($id: ID!) {
    board {
      introPopup(id: $id) {
        id
        title
        image {
          __typename
          ...thumbnailFields
          uri
        }
        urls {
          id
          url
          platform
        }
        url
        content
        startedAt
        endedAt
        exposed
        contentType
        indexNo
      }
    }
  }
  ${PROFILE_CONTENT_FRAGMENT}
`;
