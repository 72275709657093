import Content from '@/layout/Content.vue';

const FripNotice = (): Promise<typeof import('*.vue')> =>
  import('@/domain/notice/views/FripNotice.vue');

const NoticeDetail = (): Promise<typeof import('*.vue')> =>
  import('@/domain/notice/views/NoticeDetail.vue');

const HostNoticeContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/notice/views/HostNoticeContainer.vue');

const HostNoticeDetail = (): Promise<typeof import('*.vue')> =>
  import('@/domain/notice/views/HostNoticeDetail.vue');

const NoticeList = (): Promise<typeof import('*.vue')> =>
  import('@/domain/notice/views/NoticeList.vue');

export const noticePathList = {
  path: '/notice',
  name: 'NoticeContent',
  redirect: '/notice/list/service',
  component: Content,
  children: [
    {
      path: '/notice/list/:noticeType',
      name: 'NoticeList',
      component: NoticeList,
      meta: { title: '공지사항 관리' },
    },
    {
      path: '/notice/detail/:noticeType',
      name: 'NoticeDetail',
      component: NoticeDetail,
      meta: { title: '공지사항 상세' },
      props: true,
    },
    {
      path: '/notices',
      name: 'HostNoticeContainer',
      component: HostNoticeContainer,
      meta: { title: '공지사항' },
    },
    {
      path: '/notices/:id',
      name: 'HostNoticeDetail',
      component: HostNoticeDetail,
      meta: { title: '공지사항 상세' },
      props: true,
    },
  ],
};
