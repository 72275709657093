import { Host } from '@/common/Host';
import { User } from '@/domain/serial/model/serial';
import { PageInfo } from '@frientrip/domain';

export enum NotificationMessageSender {
  FRIP = 'FRIP',
  FRIP_HOST = 'FRIP_HOST',
}

export interface MnWiseMessageEdge {
  node: MnWiseMessageAPIResponse;
  cursor: string;
}

export interface MnWiseMessageAPIResponse {
  id: string;
  sn: string;
  customMessageSn: string;
  templateCode?: string | null;
  message: string;
  user?: User;
  host?: Host;
  mobileNumber: string;
  createdAt: Date;
  sender: NotificationMessageSender;
  messageResult?: MnWiseMessageRecord;
}

export interface MnWiseMessageConnection {
  edges: MnWiseMessageEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface FixedTermInput {
  startedAt: Date;
  endedAt: Date;
}

export interface MnWiseMessageFilter {
  createdAtTerm: FixedTermInput;
  userId?: string | null;
  hostId?: string | null;
  mobileNumber?: string | null;
  messageLike?: string | null;
  sender: NotificationMessageSender;
}

export interface MnWiseMessageRecord {
  id: string;
  status: MnWiseMessageRetryState;
  resultCode?: string;
  alimtalkResultCode?: string | null;
  alimtalkResultMessage?: string | null;
  smsResultCode?: string | null;
  smsResultMessage?: string | null;
  sentAt?: Date | null;
  receivedAt?: Date | null;
}

export enum MnWiseMessageRetryState {
  NEED = 'NEED',
  DONE = 'DONE',
  NONE = 'NONE',
}
