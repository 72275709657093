import Content from '@/layout/Content.vue';

const SocialFeedList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "SocialFeed" */ '@/domain/socialFeed/views/SocialFeedList.vue'
  );

export const socialFeedPathList = {
  path: '/social-feed',
  name: 'SocialFeedContent',
  redirect: '/social-feed/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'SocialFeedList',
      component: SocialFeedList,
      meta: { title: '피드관리' },
    },
  ],
};
