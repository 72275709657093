const filters = {
  /**
   * 숫자를 입력으로 받아 천 단위 마다 콤마(,)를 더해 포매팅합니다.
   */
  formatNumber(num?: number | string | null): string {
    if (num === null || Number.isNaN(Number(num))) {
      return '0';
    }

    const isNegative = Number(num) < 0;

    const formatIterator = (num: string): string[] => {
      if (num.length > 3) {
        return formatIterator(num.substring(0, num.length - 3)).concat(
          num.substring(num.length - 3)
        );
      }
      return [num];
    };

    return `${isNegative ? '-' : ''}${
      formatIterator(Math.abs(Number(num)).toString()).join(',') || '0'
    }`;
  },
};

export default filters;
