import Content from '@/layout/Content.vue';

const UserList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "user" */ '@/domain/user/views/UserList.vue');
const UserForm = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "user" */ '@/domain/user/views/UserForm.vue');

export const userPathList = {
  path: '/user',
  name: 'UserContent',
  redirect: 'list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'user List',
      component: UserList,
      children: [],
    },
    {
      path: 'list/:id',
      name: 'user Form',
      component: UserForm,
    },
  ],
};
