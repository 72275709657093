<template>
  <!-- Left div because b-navbar does not trigger on mouseenter / mouseleave events -->
  <div
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
    @mouseenter="$sidebar.onMouseEnter()"
    @mouseleave="$sidebar.onMouseLeave()"
    :data="backgroundColor"
  >
    <div
      class="scrollbar-inner"
      ref="sidebarScrollArea"
      :style="!isMobile && 'padding-bottom: 8rem'"
    >
      <div class="sidenav-header d-flex align-items-center">
        <b-navbar-brand>
          <b-link :to="`/`">
            <icon name="iconSignature" :width="80" :height="30"></icon>
          </b-link>
        </b-navbar-brand>

        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler d-none d-xl-block"
            v-if="$sidebar.isMinimized"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSidebar"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
          <div
            class="sidenav-toggler pr-3 d-block d-sm-none"
            :class="{ active: !$sidebar.isMinimized }"
            @click="minimizeSmSidebar"
          >
            <icon name="iconDelete"></icon>
          </div>
        </div>
      </div>

      <div class="px-4 py-3 d-flex" v-if="isHostAdmin">
        <router-link
          class="btn frip-button btn-outline-frip-secondary flex-grow-1"
          to="/product/create"
          active-class="''"
        >
          프립 등록
        </router-link>

        <router-link
          class="btn frip-button btn-outline-frip-primary flex-grow-1"
          to="/product/list"
          active-class="''"
        >
          프립 목록
        </router-link>
      </div>

      <div
        class="navbar-inner links-after-pad-wrapper"
        :class="isHostAdmin && 'overflow-auto'"
      >
        <b-navbar-nav>
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
            >
              <sidebar-item
                v-for="(subLink, index) in link.children"
                :key="subLink.name + index"
                :link="subLink"
              >
              </sidebar-item>
            </sidebar-item>
          </slot>
        </b-navbar-nav>
        <slot name="links-after">
          <a
            v-if="isHostAdmin"
            href="https://frip.notion.site/cb12fdd6682245668a7ceca382f7953f"
            target="_blank"
            @click="onClickMenuBanner"
          >
            <img
              src="@/assets/img/banner/hostFaqBanner_20231212_2.jpg"
              alt="자주 묻는 질문"
              width="100%"
              style="border-radius: 10px"
            />
          </a>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import AwsKinesis from '@/lib/awsKinesis';
import BannerClickEvent from '@/lib/awsKinesis/events/BannerClickEvent';
import { isHostAdmin } from '@/env';
import { version } from '../../../package';

export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title',
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title',
    },
    logo: {
      type: String,
      default:
        'https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png',
      description: 'Sidebar app logo',
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these.",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      isHostAdmin: isHostAdmin(),
      version: version,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    adminText() {
      return this.isHostAdmin ? 'Host Admin' : 'Admin';
    },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
    this.minimizeSidebar();
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    minimizeSmSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
        this.$sidebar.showSidebar = false;
      }
    },
    onClickMenuBanner() {
      new AwsKinesis().pushRecord(
        new BannerClickEvent(
          'sidebar_menu',
          'https://res.cloudinary.com/frientrip/image/upload/v1663132278/assets/client/%E1%84%91%E1%85%B3%E1%84%85%E1%85%B5%E1%86%B8_%E1%84%92%E1%85%A9%E1%86%B7%E1%84%80%E1%85%A2%E1%84%91%E1%85%A7%E1%86%AB_%E1%84%8B%E1%85%A1%E1%86%AB%E1%84%82%E1%85%A2.jpg'
        )
      );
    },
  },
};
</script>

<!-- TODO FIXME: 상단 로고 영역(160px) 매직넘버 관리 -->
<style lang="scss" scoped>
.links-after-pad-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: calc(100% - 160px);
}
</style>
