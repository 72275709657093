import Content from '@/layout/Content.vue';

const RefundListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "refund" */ '@/domain/refund/views/RefundListContainer.vue'
  );

export const refundPathList = {
  path: '/refund',
  name: 'RefundContent',
  redirect: '/refund/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'RefundListContainer',
      component: RefundListContainer,
      meta: { title: '환불 관리' },
    },
  ],
};
