










































































import Vue from 'vue';
import { Term } from '@/common/Term';

export default Vue.extend({
  name: 'Term',
  props: {
    label: {
      type: String,
    },
    value: {
      type: Object,
    },
    required: {
      type: Boolean,
      default: false,
    },
    question: {
      type: String,
    },
    startedAtLabel: {
      type: String,
      // default: '시작',
    },
    endedAtLabel: {
      type: String,
      // default: '종료',
    },
    type: {
      type: String,
      default: 'datetime-local',
    },
    labelColsLg: {
      type: String,
      default: '2',
    },
    contentColsLg: {
      type: String,
      default: '6',
    },
    simpleTerm: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startedAt: '',
      endedAt: '',
      formatType: this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss',
      termOptions: [
        { text: '오늘', value: 'today', type: 'secondary' },
        { text: '1개월', value: '1month', type: 'secondary' },
        { text: '6개월', value: '6month', type: 'secondary' },
        { text: '1년', value: '1year', type: 'secondary' },
        { text: '5년', value: '5year', type: 'secondary' },
        { text: '전체', value: 'all', type: 'primary' },
      ],
    };
  },

  computed: {
    labelCols(): string {
      if (this.label) {
        return this.labelColsLg;
      } else {
        return '';
      }
    },
    startAtPrependHtml(): string {
      if (this.startedAtLabel) {
        return `<small>${this.startedAtLabel}</small>`;
      } else {
        return '';
      }
    },
    endedAtPrependHtml(): string {
      if (this.endedAtLabel) {
        return `<small>${this.endedAtLabel}</small>`;
      } else {
        return '';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'py-1 px-3';
      } else {
        return 'py-1';
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler: function (term: Term | null) {
        if (term?.startedAt) {
          this.startedAt = this.$moment(term.startedAt).format(this.formatType);
        } else {
          this.startedAt = '';
        }
        if (term?.endedAt) {
          this.endedAt = this.$moment(term.endedAt).format(this.formatType);
        } else {
          this.endedAt = '';
        }
        if (this.startedAt === '' && this.endedAt === '') {
          this.termOptions = [
            { text: '오늘', value: 'today', type: 'secondary' },
            { text: '1개월', value: '1month', type: 'secondary' },
            { text: '6개월', value: '6month', type: 'secondary' },
            { text: '1년', value: '1year', type: 'secondary' },
            { text: '5년', value: '5year', type: 'secondary' },
            { text: '전체', value: 'all', type: 'primary' },
          ];
        }
      },
    },
  },
  created() {
    if (this.type === 'datetime-local') {
      this.formatType = 'YYYY-MM-DDTHH:mm:ss'; // datetime-local
    }
  },
  methods: {
    setInstant(): void {
      this.termOptions.forEach((option, index) => {
        this.termOptions[index].type = 'secondary';
      });
      let startedAt = new Date(this.startedAt).getTime();
      let endedAt = new Date(this.endedAt).getTime();
      if (this.type === 'date') {
        startedAt = this.$moment(this.startedAt).startOf('day').valueOf();
        endedAt = this.$moment(this.endedAt)
          .endOf('day')
          .subtract('999', 'millisecond')
          .valueOf();
      }
      const term = {
        startedAt: startedAt,
        endedAt: endedAt,
      };
      this.$emit('input', term);
    },
    setSimpleTerm(selectedIndex: number): void {
      // TODO: 일단 지금은 구현만 되어있는데 리팩토링 필요함
      console.log(this.termOptions[selectedIndex]);
      const today = this.$moment().startOf('day');
      const startedAt = today.clone();
      let endedAt = today.clone();
      this.termOptions.forEach((option, index) => {
        this.termOptions[index].type = 'secondary';
      });

      const selectedTermOption = this.termOptions[selectedIndex].value;
      this.termOptions[selectedIndex].type = 'primary';
      if (selectedTermOption === 'today') {
        startedAt.startOf('date');
      } else if (selectedTermOption === '1month') {
        startedAt.subtract('1', 'month');
      } else if (selectedTermOption === '6month') {
        startedAt.subtract('6', 'month');
      } else if (selectedTermOption === '1year') {
        startedAt.subtract('1', 'year');
      } else if (selectedTermOption === '5year') {
        startedAt.subtract('5', 'year');
      }
      if (this.type === 'datetime-local') {
        this.startedAt = startedAt.format('YYYY-MM-DDTHH:mm:ss');
        this.endedAt = endedAt.format('YYYY-MM-DDTHH:mm:ss');
      } else if (this.type === 'date') {
        // endedAt.add('1', 'day').subtract('1', 'millisecond');
        this.startedAt = startedAt.format('YYYY-MM-DD');
        this.endedAt = endedAt
          .endOf('day')
          .subtract('999', 'millisecond')
          .format('YYYY-MM-DD');
      }
      // TODO: 전체이면 값이 없어야함
      if (this.termOptions[selectedIndex].value === 'all') {
        this.startedAt = '';
        this.endedAt = '';
      }

      const term = {
        startedAt: this.startedAt === '' ? null : startedAt.valueOf(),
        endedAt: this.endedAt === '' ? null : endedAt.valueOf(),
      };
      this.$emit('input', term);
    },
  },
});
