export type Constants = {
  kakaoApiKey: string;
  facebookAppId: string;
  // 당장은 모든 환경 key 동일.
  channelTalkKey: 'c2daf29f-ede9-4b45-8839-11921f3f7381';
  amplitudeApiKey: string;
  firehoseDeliveryStreamName: string;
  talkPlusAppId: string;
};

const dev: Constants = {
  kakaoApiKey: 'b2774bb606e7431f85f5604e11fb127a',
  facebookAppId: '544244172382640',
  channelTalkKey: 'c2daf29f-ede9-4b45-8839-11921f3f7381',
  amplitudeApiKey: 'd115fcb0734614f6f1ace585b80c13da',
  firehoseDeliveryStreamName: 'host-admin-action-web-dev',
  talkPlusAppId: '933d87b5-ea2b-4922-9f1b-11bd40a8a03b',
};

const staging: Constants = {
  kakaoApiKey: '90a1878dbc5b1c03f9c7db732ac4b97f',
  facebookAppId: '488654004608324',
  channelTalkKey: 'c2daf29f-ede9-4b45-8839-11921f3f7381',
  amplitudeApiKey: 'd115fcb0734614f6f1ace585b80c13da',
  firehoseDeliveryStreamName: 'host-admin-action-web-dev',
  talkPlusAppId: '8b5705aa-accf-48bd-aeb6-6b8848948063',
};
const prod: Constants = {
  kakaoApiKey: '90a1878dbc5b1c03f9c7db732ac4b97f',
  facebookAppId: '488654004608324',
  channelTalkKey: 'c2daf29f-ede9-4b45-8839-11921f3f7381',
  amplitudeApiKey: 'ce1d81a3db567a342c67d3217b49e254',
  firehoseDeliveryStreamName: 'host-admin-action-web-prod',
  talkPlusAppId: '8b5705aa-accf-48bd-aeb6-6b8848948063',
};

export const CONSTANTS = {
  dev,
  staging,
  prod,
};
