import Content from '@/layout/Content.vue';

const DisplayAreaContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "area" */ '@/domain/area/views/DisplayAreaContainer.vue'
  );

export const areaPathList = {
  path: '/area',
  name: 'AreaContent',
  redirect: '/area/display/list',
  component: Content,
  children: [
    {
      path: '/area/display/list',
      name: 'Area',
      component: DisplayAreaContainer,
      meta: { title: '전시 지역 관리' },
    },
  ],
};
