import Content from '@/layout/Content.vue';

const BannerSlotList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/banner/views/BannerSlotList.vue'
  );
const BannerSlotForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/banner/views/BannerSlotForm.vue'
  );
const BannerList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/banner/views/BannerList.vue'
  );
const BannerForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "banner" */ '@/domain/banner/views/BannerForm.vue'
  );

export const bannerPathList = {
  path: '/banner',
  name: 'BannerContent',
  redirect: '/banner/slot/list',
  component: Content,
  children: [
    {
      path: 'slot/list',
      name: 'BannerSlotList',
      component: BannerSlotList,
      meta: { title: '배너 슬롯' },
    },
    {
      path: 'slot/create',
      name: 'BannerSlotCreate',
      component: BannerSlotForm,
      meta: { title: '배너 등록' },
    },
    {
      path: 'slot/:slotId',
      name: 'BannerSlotUpdate',
      component: BannerSlotForm,
      meta: { title: '배너 슬롯 상세' },
    },
    {
      path: 'slot/list/:slotId',
      name: 'BannerSlotForm',
      component: BannerSlotForm,
      meta: { title: '배너 슬롯 상세' },
    },
    {
      path: 'slot/list/:slotId/list',
      name: 'BannerList',
      component: BannerList,
      meta: { title: '배너' },
    },
    {
      path: 'slot/list/:slotId/list/:bannerId',
      name: 'BannerUpdate',
      component: BannerForm,
      meta: { title: '배너 상세' },
    },
    {
      path: 'slot/list/:slotId/create',
      name: 'BannerCreate',
      component: BannerForm,
      meta: { title: '배너 생성' },
    },
  ],
};
