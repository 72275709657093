<template>
  <SlideYUpTransition :duration="animationDuration">
    <b-modal
      class="modal fade"
      ref="app-modal"
      :size="size"
      :hide-header="!$slots.header"
      :modal-class="[...modalClasses]"
      @mousedown.self="closeModal"
      tabindex="-1"
      role="dialog"
      centered
      :no-close-on-backdrop="!allowOutSideClick"
      @close="closeModal"
      @hide="closeModal"
      :header-class="headerClasses"
      :footer-class="footerClasses"
      :content-class="[...modalContentClasses]"
      :body-class="bodyClasses"
      :aria-hidden="!show"
    >
      <template v-slot:modal-header>
        <slot name="header"></slot>
        <slot name="close-button">
          <button
            type="button"
            class="close"
            v-if="showClose"
            @click="closeModal"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span :aria-hidden="!show">×</span>
          </button>
        </slot>
      </template>

      <div class="text-center p-3">
        <slot>
          <div v-if="showAlertIcon" :class="[`text-frip-${type}`, 'my-3']">
            <i :class="alertIcon" style="font-size: 2.5rem"></i>
          </div>
          <h2>{{ title }}</h2>
          <div>
            <div>{{ message }}</div>
            <div v-if="html !== ''" v-html="html"></div>
          </div>
        </slot>
      </div>

      <slot></slot>

      <template v-slot:modal-footer>
        <slot name="footer">
          <b-container class="text-center p-3">
            <frip-button
              :type="confirmType"
              tab
              @click="confirmModal"
              :block="isMobile"
            >
              {{ confirmText }}
            </frip-button>
            <frip-button
              v-if="showCancelButton"
              tab
              :type="cancelButtonType"
              @click="cancelModal"
            >
              {{ cancelText }}
            </frip-button>
          </b-container>
        </slot>
      </template>
    </b-modal>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'FripAlertModal',
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes',
    },
    size: {
      type: String,
      description: 'Modal size',
      validator(value) {
        let acceptedValues = ['', 'sm', 'lg'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes',
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes',
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes',
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes',
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration',
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: String,
      default: 'default',
      validator(value) {
        let acceptedValues = [
          'default',
          'primary',
          'danger',
          'warning',
          'success',
          'secondary',
          'info',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    icon: {
      type: String,
    },
    showAlertIcon: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '확인',
    },
    confirmButtonType: {
      type: String,
    },
    cancelText: {
      type: String,
      default: '취소',
    },
    cancelButtonClass: {
      type: String,
      default: 'secondary',
    },
    cancelButtonType: {
      type: String,
      default: 'secondary',
    },
    allowOutSideClick: {
      type: Boolean,
      description: 'modal background click close',
      default: false,
    },
    html: {
      type: String,
      description: 'modal html',
      default: '',
    },
  },
  computed: {
    alertIcon() {
      if (this.icon) {
        return this.icon;
      } else {
        const alertIconMap = {
          danger: 'fas fa-exclamation-circle',
          warning: 'fas fa-exclamation-circle',
          success: 'fas fa-check-circle',
          info: 'fas fa-info-circle',
          primary: 'fas fa-info-circle',
          default: 'fas fa-info-circle',
          secondary: 'fas fa-question-circle',
        };
        // return alertIconMap[this.type] || '';
        return '';
      }
    },
    confirmType() {
      return this.confirmButtonType ? this.confirmButtonType : this.type;
    },
    cancelType() {
      return this.cancelButtonType ? this.cancelButtonType : 'secondary';
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$refs['app-modal'].show();
      } else {
        this.$refs['app-modal'].hide();
      }
    },
  },
  mounted() {
    console.log(this.html);
  },
  methods: {
    confirmModal() {
      this.$emit('confirm');
      this.$emit('update:show', false);
    },
    cancelModal() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
};
</script>
<style>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
</style>
