import Content from '@/layout/Content.vue';

const AccountList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/account/AccountList.vue'
  );
const RequestAccount = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/account/RequestAccount.vue'
  );
const AddAccount = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/account/AddAccount.vue'
  );
const UpdateAccount = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/account/UpdateAccount.vue'
  );
const RoleList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/role/RoleList.vue'
  );
const AddRole = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/role/AddRole.vue'
  );
const UpdateRole = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/role/UpdateRole.vue'
  );
const PermissionList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/permission/PermissionList.vue'
  );
const AddPermission = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/permission/AddPermission.vue'
  );
const UpdatePermission = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "authority" */ '@/domain/authority/views/permission/UpdatePermission.vue'
  );

export const authorityPathList = {
  path: '/manager',
  name: 'ManagerContent',
  redirect: 'manager/account/list',
  component: Content,
  children: [
    {
      path: 'account/request',
      name: 'RequestAccount',
      component: RequestAccount,
    },
    {
      path: 'account/list',
      name: 'Account List',
      component: AccountList,
    },
    {
      path: 'account/list/update',
      name: 'Update Account',
      component: UpdateAccount,
      props: true,
    },
    {
      path: 'account/add',
      name: 'Add Account',
      component: AddAccount,
    },
    {
      path: 'role/list',
      name: 'Role List',
      component: RoleList,
    },
    {
      path: 'role/list/update',
      name: 'Update Role',
      component: UpdateRole,
      props: true,
    },
    {
      path: 'role/add',
      name: 'Add Role',
      component: AddRole,
    },
    {
      path: 'permission/list',
      name: 'Permission List',
      component: PermissionList,
    },
    {
      path: 'permission/list/update',
      name: 'Update Permission',
      component: UpdatePermission,
      props: true,
    },
    {
      path: 'permission/add',
      name: 'Add Permission',
      component: AddPermission,
    },
  ],
};
