var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ display: 'inline-block' })},[_c('div',{style:({ alignItems: 'center', display: 'flex' })},[_c('div',{style:({
        width: '28px',
        height: '28px',
        borderRadius: '100%',
        background: this.checked || this.filled ? "#000000" : '#ffff',
        color: this.checked || this.filled ? "#ffff" : '#AAAAAA',
        border: '1px solid #EEEEEE',
        position: 'relative',
        display: 'inline-block',
      })},[(_vm.checked)?_c('div',{style:({
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        })},[_c('img',{attrs:{"src":require("@/assets/icons/check-white.svg"),"width":"24"}})]):_c('div',{style:({
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '14px',
        })},[_c('div',{staticClass:"numberText"},[_vm._t("default")],2)])]),_c('span',{staticClass:"labelText",style:({ paddingLeft: '8px' })},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }