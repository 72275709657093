import Content from '@/layout/Content.vue';

const CouponList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "coupon" */ '@/domain/coupon/views/CouponList.vue'
  );

const CouponDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "coupon" */ '@/domain/coupon/views/CouponDetail.vue'
  );

const IssuedCouponList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "coupon" */ '@/domain/coupon/views/IssuedCouponList.vue'
  );

const DownloadCouponList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "coupon" */ '@/domain/coupon/views/DownloadCouponListContainer.vue'
  );

const DownloadCouponDetail = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "coupon" */ '@/domain/coupon/views/DownloadCouponDetail.vue'
  );

export const couponPathList = {
  path: '/coupon',
  name: 'CouponContent',
  redirect: '/coupon/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'CouponList',
      component: CouponList,
      meta: { title: '쿠폰' },
    },
    {
      path: 'list/:id',
      name: 'CouponDetail',
      component: CouponDetail,
      meta: { title: '쿠폰 상세' },
    },
    {
      path: 'list/issued/list',
      name: 'IssuedCouponList',
      component: IssuedCouponList,
      meta: { title: '발급된 쿠폰' },
    },
    {
      path: 'create',
      name: 'CouponCreate',
      component: CouponDetail,
      meta: { title: '쿠폰 등록' },
    },
    {
      path: 'download-coupon/list',
      name: 'DownloadCouponList',
      component: DownloadCouponList,
      meta: { title: '기획전/매거진 쿠폰 관리' },
    },
    {
      name: 'DownloadCouponDetail',
      path: 'download-coupon/detail/:id',
      component: DownloadCouponDetail,
      meta: { title: '다운로드 쿠폰 관리' },
    },
    {
      name: 'DownloadCouponCreate',
      path: 'download-coupon/create',
      component: DownloadCouponDetail,
      meta: { title: '다운로드 쿠폰 관리' },
    },
  ],
};
