import { CommonState, PageInfo } from '@frientrip/domain';

export const HOST = 'HOST';
export const SERVICE = 'SERVICE';

export interface Notice {
  id: number;
  title: string;
  content: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  forHostOnly: boolean;
  status: CommonState;
}

export type CategoryType = {
  [index: string]: string;
  SERVICE_COMMON: string;
  SERVICE_EVENT: string;
  SERVICE_NOTICE: string;
  HOST_NOTICE: string;
  HOST_COMMON: string;
  HOST_EVENT: string;
};

export const NoticeType: CategoryType = {
  SERVICE_COMMON: '일반',
  SERVICE_EVENT: '이벤트',
  SERVICE_NOTICE: '공지',
  HOST_NOTICE: '공지',
  HOST_COMMON: '일반',
  HOST_EVENT: '이벤트',
};

export interface NoticeParam {
  id?: number;
  title: string;
  content: string;
  type: string;
  forHostOnly: boolean;
}

export interface NoticeFilter {
  id?: number;
  titleLike?: string;
  type?: string;
  forHostOnly: boolean;
}

export interface NoticeConnection {
  edges: NoticeEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface NoticeEdge {
  node: Notice;
  cursor: string;
}

export interface NoticeTypeInfo {
  label: string;
  value: string;
}
export interface NoticeTypeParam {
  forHostOnly: boolean;
}

export interface Result {
  success: boolean;
  message: string;
}

export const NoticeTypeLabel: { label: string; type: string }[] = [
  {
    label: NoticeType.HOST_NOTICE,
    type: 'primary',
  },
  {
    label: NoticeType.HOST_COMMON,
    type: 'success',
  },
  {
    label: NoticeType.HOST_EVENT,
    type: 'info',
  },
];
