import Content from '@/layout/Content.vue';

const PurchaseListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "purchase" */ '@/domain/purchase/views/PurchaseListContainer.vue'
  );

const PurchaseItemViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "purchase" */ '@/domain/purchase/views/PurchaseItemViewContainer.vue'
  );

export const purchasePathList = {
  path: '/purchase',
  name: 'PurchaseContent',
  redirect: '/purchase/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'PurchaseListContainer',
      component: PurchaseListContainer,
      meta: { title: '판매 관리' },
    },
    {
      path: 'view/:id',
      name: 'PurchaseItemViewContainer',
      component: PurchaseItemViewContainer,
      meta: { title: '판매 상세내역' },
    },
  ],
};
