import Content from '@/layout/Content.vue';

const PointList = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "point" */ '@/domain/point/views/PointList.vue');

const GivePoint = (): Promise<typeof import('*.vue')> =>
  import(/* webpackChunkName: "point" */ '@/domain/point/views/GivePoint.vue');

const GivePointList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "point" */ '@/domain/point/views/GivePointList.vue'
  );

export const pointPathList = {
  path: '/points',
  name: 'PointContent',
  redirect: '/points/list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'PointList',
      component: PointList,
      meta: { title: '에너지' },
    },
    {
      path: 'give',
      name: 'GivePoint',
      component: GivePoint,
      meta: { title: '에너지 수동 지급' },
    },
    {
      path: 'give/list',
      name: 'GivePointList',
      component: GivePointList,
      meta: { title: '에너지 수동 지급 내역' },
    },
  ],
};
