import Content from '@/layout/Content.vue';

const FaqContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faq/views/FaqContainer.vue');

const AdminFaqContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/faq/views/AdminFaqContainer.vue');

export const faqPathList = {
  path: '/faq',
  name: 'FaqContent',
  redirect: '/faq',
  component: Content,
  children: [
    {
      path: '/faq',
      name: 'FaqContainer',
      component: FaqContainer,
      meta: { title: '자주 묻는 질문' },
    },
    {
      path: '/faq/list',
      name: 'AdminFaqContainer',
      component: AdminFaqContainer,
      meta: { title: '자주 묻는 질문' },
    },
  ],
};
