import { CollectionCondition } from './condition';
import { Collection, CollectionConditionCreateParam } from './collection';

export interface CollectionSlot {
  id: string;
  description: string;
  maximumCountOfCollection: number;
  collectionType: CollectionType;
  conditions: CollectionCondition[];
  collections: Collection[];
  countOfVisibleCollections: number;
}

export enum CollectionType {
  PRODUCT = 'PRODUCT',
  THEME = 'THEME',
}
export interface CollectionSlotUpdateParam {
  id: string;
  description: string;
  maximumCountOfCollection: number;
  conditions?: CollectionConditionCreateParam[];
}

export interface CollectionSlotCreateParam {
  id: string;
  conditions: CollectionConditionCreateParam[];
  description: string;
  maximumCountOfCollection: number;
  collectionType: CollectionType;
}
