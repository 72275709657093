<template>
  <div class="content">
    <div
      class="bg-white wg-100 p-3 font-weight-bold"
      :class="{ 'text-sm': isMobile }"
      v-if="pageName !== ''"
    >
      {{ pageName }}
    </div>
    <FadeTransition :duration="200" mode="out-in">
      <!-- your content here -->
      <keep-alive
        :include="['PointList', 'ProductReviewList', 'GivePoint']"
        :exclude="['HostForm']"
      >
        <router-view
          :class="{ 'm-3': !isMobile, 'my-2': isMobile }"
        ></router-view>
      </keep-alive>
    </FadeTransition>
  </div>
</template>

<script>
import { FadeTransition } from 'vue2-transitions';

export default {
  components: {
    FadeTransition,
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    pageName() {
      return this.$route.meta.title || '';
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
<style></style>
