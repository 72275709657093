import Content from '@/layout/Content.vue';

const OrderListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "order" */ '@/domain/order/views/OrderListContainer.vue'
  );

const TransactionListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "order" */ '@/domain/order/views/TransactionListContainer.vue'
  );

const OrderContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "order" */ '@/domain/order/views/OrderContainer.vue'
  );

export const orderPathList = {
  path: '/order',
  name: 'OrderContent',
  redirect: '/order/transactions',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'OrderListContainer',
      component: OrderListContainer,
      meta: { title: '주문 관리' },
    },
    {
      path: 'transactions',
      name: 'TransactionListContainer',
      component: TransactionListContainer,
      meta: { title: '주문 관리' },
    },
    {
      path: 'view/:id',
      name: 'OrderContainer',
      component: OrderContainer,
      meta: { title: '주문 내역' },
    },
  ],
};
