<template>
  <div>
    <footer
      v-if="!isMobile"
      class="hostAdminFooter footerText d-none d-sm-block"
    >
      <b-row class="py-3">
        <b-col class="text-center" lg="1"> (주) 프렌트립 </b-col>
        <b-col lg="8">
          주소 : 서울특별시 성동구 왕십리로 115 헤이그라운드 서울숲점 G703<br />
          대표 : 임수열 | 사업자등록번호: 261-81-04385 l 통신판매업신고번호 :
          2016-서울성동-01088 <br />
          <router-link
            to="/static-info/privacy"
            class="font-weight-bold text-muted"
          >
            개인정보 처리방침
          </router-link>
          &nbsp;l&nbsp;
          <router-link
            to="/static-info/hostAgreement"
            class="font-weight-bold text-muted"
          >
            이용약관
          </router-link>
          &nbsp;l&nbsp;개인정보취급책임자 : 양사열
          <div class="font-weight-bold text-muted">
            Frip Host Admin v{{ version }}
          </div>
          <div class="pt-3">© Frientrip. all rights reserved.</div>
        </b-col>
        <b-col lg="2"></b-col>
      </b-row>
    </footer>
    <footer v-else class="bg-frip-default text-frip-gray text-xs px-3">
      <b-row class="py-3">
        <b-col lg="1"> (주) 프렌트립 </b-col>
        <b-col lg="8">
          주소 : 서울특별시 성동구 왕십리로 115 헤이그라운드 서울숲점 G703<br />
          대표 : 임수열 | 사업자등록번호: 261-81-04385 l 통신판매업신고번호 :
          2016-서울성동-01088 <br />
          <router-link to="/static-info/privacy" class="text-frip-gray">
            개인정보 처리방침
          </router-link>
          &nbsp;l&nbsp;
          <router-link to="/static-info/hostAgreement" class="text-frip-gray">
            이용약관
          </router-link>
          &nbsp;l&nbsp;개인정보취급책임자 : 양사열
          <div class="font-weight-bold text-frip-gray">
            Frip Host Admin v{{ version }}
          </div>
          <div class="pt-2">© Frientrip. all rights reserved.</div>
        </b-col>
        <b-col lg="2"></b-col>
      </b-row>
    </footer>
  </div>
</template>
<script>
import { version } from '../../package';

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      version: version,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
};
</script>
<style>
.hostAdminFooter {
  background: #333333;
  z-index: 9999;
  width: 100%;
  bottom: 0;
  height: 7rem;
  position: fixed;
}
.footerText {
  /* Regular 11 */

  font-family: Noto Sans CJK KR;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  /* or 140% */

  letter-spacing: -0.5px;

  /* Gray/gray500 */

  color: #999999;
}
</style>
