



















































import Vue from 'vue';
export default Vue.extend({
  name: 'InlineRadioForm',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    value: {
      type: [String, Object, Number, Boolean],
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    block: {
      type: Boolean,
    },
    contentColsLg: {
      type: String,
      default: '10',
    },
    disabled: Boolean,
    cardType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: null,
    };
  },
  computed: {
    labelCols(): string {
      if (this.label) {
        return '2';
      } else {
        return '0';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'pt-1 px-3';
      } else {
        return 'pt-1';
      }
    },
    radioRowClass(): string {
      return this.cardType ? 'radio-row' : '';
    },
    radioCardClass(): string {
      return this.cardType ? 'card-type-radio' : 'mt-3';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        // TODO: 타입 붙여야함
        this.selectedOption = val;
      },
    },
  },
  created() {
    this.selectedOption = this.value;
  },
  methods: {
    onSelect(selectedOption: string) {
      this.$emit('input', selectedOption);
    },
  },
});
