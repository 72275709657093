import Content from '@/layout/Content.vue';

const FripMagazineList = (): Promise<typeof import('*.vue')> =>
  import('@/domain/magazine/views/MagazineListContainer.vue');

const FripMagazineSaveContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/magazine/views/MagazineSaveContainer.vue');

export const magazineList = {
  path: '/magazine',
  name: 'Magazine',
  redirect: 'app',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'MagazineListContainer',
      component: FripMagazineList,
      meta: { title: '매거진 리스트' },
    },
    {
      path: 'list/:id',
      name: 'MagazineUpdateContainer',
      component: FripMagazineSaveContainer,
      meta: { title: '매거진 수정' },
    },
    {
      path: 'create',
      name: 'MagazineCreateContainer',
      component: FripMagazineSaveContainer,
      meta: { title: '매거진 생성' },
    },
  ],
};
