import Content from '@/layout/Content.vue';

const Privacy = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "static-info" */ '@/domain/staticInfo/views/Privacy.vue'
  );
const HostAgreement = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "static-info" */ '@/domain/staticInfo/views/HostAgreement.vue'
  );

export const staticInfoPathList = {
  path: '/static-info',
  name: 'StaticInfo',
  redirect: '/static-info/privacy',
  component: Content,
  children: [
    {
      path: 'privacy',
      name: 'Privacy',
      component: Privacy,
    },
    {
      path: 'hostAgreement',
      name: 'HostAgreement',
      component: HostAgreement,
    },
  ],
};
