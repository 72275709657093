




















































import Vue from 'vue';
export default Vue.extend({
  name: 'InlineCheckboxInput',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
    },
    value: {
      type: Array,
    },
    options: {
      type: Array,
    },
    name: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    question: {
      type: String,
    },
    caption: {
      type: String,
    },
    emptyText: {
      type: String,
    },
    block: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: this.value,
    };
  },
  computed: {
    labelCols(): string {
      if (this.label) {
        return '2';
      } else {
        return '0';
      }
    },
    paddingClass(): string {
      if (this.block) {
        return 'pl-3';
      } else {
        return '';
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.selectedOption = newValue;
      },
    },
  },
  methods: {
    onSelect(selectedOption: string) {
      this.$emit('input', selectedOption);
    },
  },
});
