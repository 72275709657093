import ContentCard from '@/components/FripComponents/ContentCard.vue';
import FripButton from '@/components/FripComponents/FripButton.vue';
import FripAlert from '@/components/FripComponents/FripAlert.vue';
import FripBadge from '@/components/FripComponents/FripBadge.vue';
import FripModal from '@/components/FripComponents/FripModal.vue';
import FripCircleText from '@/components/FripComponents/FripCircleText.vue';
import FripAlertModal from '@/components/FripComponents/plugins/FripAlertModalPlugin/FripAlertModal.vue';
import FripAlertModalContainer from '@/components/FripComponents/plugins/FripAlertModalPlugin/FripAlertModalContainer.vue';
import InlineInput from '@/components/Forms/InlineInput.vue';
import InlineRadioInput from '@/components/Forms/InlineRadioInput.vue';
import InlineCheckboxInput from '@/components/Forms/InlineCheckboxInput.vue';
import InputRow from '@/components/Forms/InputRow.vue';
import InlineText from '@/components/Forms/InlineText.vue';
import TermInput from '@/components/Forms/TermInput.vue';
import FormTitle from '@/components/Labels/FormTitle.vue';
import Icon from '@/components/Icon/Icon.vue';

export const GlobalFripComponents = {
  install(Vue) {
    Vue.component('content-card', ContentCard);
    Vue.component('frip-button', FripButton);
    Vue.component('frip-alert', FripAlert);
    Vue.component('frip-badge', FripBadge);
    Vue.component('frip-modal', FripModal);
    Vue.component('frip-alert-modal', FripAlertModal);
    Vue.component('frip-alert-modal-container', FripAlertModalContainer);
    Vue.component('frip-circle-text', FripCircleText);
    Vue.component('inline-input', InlineInput);
    Vue.component('inline-radio-input', InlineRadioInput);
    Vue.component('inline-checkbox-input', InlineCheckboxInput);
    Vue.component('input-row', InputRow);
    Vue.component('inline-text', InlineText);
    Vue.component('term-input', TermInput);
    Vue.component('form-title', FormTitle);
    Vue.component('icon', Icon);
  },
};
