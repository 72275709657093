/* eslint-disable prettier/prettier */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { DashboardPlugin } from './plugins/dashboard-plugin';
import { FripAlertPlugin } from './components/FripComponents/plugins/FripAlertModalPlugin';
import VueApollo from 'vue-apollo';
import { apolloClient, dataApolloClient } from './apolloClient';
import VueMoment from 'vue-moment';
import ToggleButton from 'vue-js-toggle-button';
import { onlyNumber } from './common/directive/onlyNumber';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
import 'froala-editor/js/plugins/font_family.min';
import 'froala-editor/js/languages/ko';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { extend } from 'vee-validate';
import VueFroala from 'vue-froala-wysiwyg';
import VueKakaoSdk from 'vue-kakao-sdk';
import VCalendar from 'v-calendar';
import FacebookSdk from './lib/facebookSDK';
import { getConstants, getPageTitle } from './env';
import WellknownLinkFunc from './plugins/WellknownLink';
import { maxLimitMixin } from './common/directive/maxLimit';
import { minLimitMixin } from './common/directive/minLimit';
import amplitude from 'amplitude-js';
import AwsKinesis from '@/lib/awsKinesis';
import VueMeta from 'vue-meta';

amplitude.getInstance().init(getConstants().amplitudeApiKey);
new AwsKinesis().initialize();

Vue.use(VueFroala);
Vue.use(VueKakaoSdk, { apiKey: getConstants().kakaoApiKey }); // apiKey 를 반드시 입력해줘야한다.
Vue.use(FacebookSdk, { appId: getConstants().facebookAppId }); // apiKey 를 반드시 입력해줘야한다.
Vue.use(VCalendar, { componentPrefix: 'vc' });
Vue.use(VueMeta, {
  attribute: 'data-vue-meta',
});

const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
    dataApolloClient,
  },
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'loading',
  },
});

Vue.use(DashboardPlugin);
Vue.use(VueApollo);
Vue.use(VueMoment);
Vue.use(FripAlertPlugin);
Vue.use(ToggleButton);
Vue.use(WellknownLinkFunc);
Vue.directive('onlyNumber', onlyNumber);
Vue.directive('min', minLimitMixin);
Vue.directive('max', maxLimitMixin);

Vue.config.productionTip = false;

new Vue({
  apolloProvider,
  router,
  store,
  created() {
    extend('password', password => {
      const passwordRegExpList = [
        /[0-9]+/, // 숫자
        /[a-z]+/, // 소문자
        /[A-Z]+/, // 대문자
        /[$@^#!%*?&]+/, // 특수문자
      ];
      const resultList = passwordRegExpList.map(regExp =>
        regExp.test(password)
      );
      return (
        String(password).length >= 10 &&
        resultList.filter(result => result).length >= 2
      );
    });
    extend('passwordCheck', (passwordCheck, param = { password: '' }) => {
      const passwordRegExpList = [
        /[0-9]+/, // 숫자
        /[a-z]+/, // 소문자
        /[A-Z]+/, // 대문자
        /[$@^#!%*?&]+/, // 특수문자
      ];
      const resultList = passwordRegExpList.map(regExp =>
        regExp.test(passwordCheck)
      );
      return (
        String(passwordCheck).length >= 10 &&
        resultList.filter(result => result).length >= 2 &&
        passwordCheck === (param as { password: '' }).password
      );
    });
    extend('mobileNumber', number => {
      const regExp = /^01([0|1|6|7|8|9])?([0-9]{3,4})?([0-9]{4})$/;
      return regExp.test(number) && number.length < 12 && number.length > 9;
    });
    extend('phoneNumber', number => {
      const regExp = /^([0-9]{3})?([0-9]{3,4})?([0-9]{4})$/;
      return regExp.test(number) && number.length < 12 && number.length > 9;
    });
    extend('email', email => {
      const emailRegExp =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return emailRegExp.test(email);
    });
  },
  metaInfo: {
    title: getPageTitle(),
  },
  render: h => h(App),
}).$mount('#app');
