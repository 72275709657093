import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  IntroPopup,
  IntroPopupChangedIndexNoParam,
  IntroPopupExposureParam,
  IntroPopupParam,
} from '@/domain/introPopup/model/introPopup';
import {
  CHANGE_INTRO_POPUP_INDEX_NUMBER,
  DELETE_INTRO_POPUP,
  EXPOSE_INTRO_POPUP,
  REGISTER_INTRO_POPUP,
  UPDATE_INTRO_POPUP,
} from '@/domain/introPopup/queries/mutation';

export class IntroPopupService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async registerIntroPopup(param: IntroPopupParam): Promise<IntroPopup> {
    const response = await this.apollo.mutate({
      mutation: REGISTER_INTRO_POPUP,
      variables: { param: param },
    });

    return response.data.registerIntroPopup;
  }

  async updateIntroPopup(param: IntroPopupParam): Promise<IntroPopup> {
    const response = await this.apollo.mutate({
      mutation: UPDATE_INTRO_POPUP,
      variables: { param: param },
    });

    return response.data.updateIntroPopup;
  }

  async changeIntroPopupIndexNo(
    param: IntroPopupChangedIndexNoParam[]
  ): Promise<boolean> {
    console.log(param);
    const response = await this.apollo.mutate({
      mutation: CHANGE_INTRO_POPUP_INDEX_NUMBER,
      variables: { param: param },
    });

    return response.data.changeIntroPopupsIndexNo;
  }

  async deleteIntroPopup(id: number): Promise<boolean> {
    const response = await this.apollo.mutate({
      mutation: DELETE_INTRO_POPUP,
      variables: { id: id },
    });

    return response.data.deleteIntroPopup;
  }

  async exposeIntroPopup(param: IntroPopupExposureParam): Promise<boolean> {
    console.log(param);
    const response = await this.apollo.mutate({
      mutation: EXPOSE_INTRO_POPUP,
      variables: { param: param },
    });

    return response.data.exposeIntroPopup;
  }
}
