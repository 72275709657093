import _Vue, { PluginFunction } from 'vue';

interface FacebookSdkOption {
  appId: string;
}
export interface FacebookSdk {
  install: PluginFunction<FacebookSdkOption>;
}
declare module 'vue/types/vue' {
  interface Vue {
    $facebook: {
      login: any;
    };
  }
}

declare const FB: any;
declare const window: any;

export default {
  async install(Vue: typeof _Vue, options: any) {
    // // 1. 전역 메소드 또는 속성 추가
    initFacebookSdk(options, Vue);
  },
};

export async function initFacebookSdk(options: any, Vue: typeof _Vue) {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      FB.init({
        appId: options.appId,
        cookie: true,
        xfbml: true,
        version: 'v8.0',
      });

      Vue.prototype.$facebook = FB;
      // auto authenticate with the api if already logged in with facebook
      // FB.getLoginStatus(({ authResponse }) => {
      //   if (authResponse) {
      //     accountService
      //       .apiAuthenticate(authResponse.accessToken)
      //       .then(resolve);
      //   } else {
      //     resolve();
      //   }
      // });
    };
    // load facebook sdk script
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      (js as any).src = 'https://connect.facebook.net/en_US/sdk.js';
      (fjs as any).parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}
