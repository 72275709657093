import Content from '@/layout/Content.vue';

const AccountListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/AccountListContainer.vue'
  );
const SettlementListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/SettlementListContainer.vue'
  );
const SettlementViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/SettlementViewContainer.vue'
  );
const InvoiceListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/InvoiceListContainer.vue'
  );
const InvoiceViewContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/InvoiceViewContainer.vue'
  );
const TaxInvoiceListContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "settlement" */ '@/domain/settlement/views/TaxInvoiceListContainer.vue'
  );
export const settlementPathList = {
  path: '/settlement',
  name: 'SettlementContent',
  redirect: '/settlement/settlements',
  component: Content,
  children: [
    {
      path: 'accounts',
      name: 'AccountListContainer',
      component: AccountListContainer,
      meta: { title: '정산 계좌 목록' },
    },
    {
      path: 'settlements',
      name: 'SettlementListContainer',
      component: SettlementListContainer,
      meta: { title: '정산서 목록' },
    },
    {
      path: 'settlements/:id',
      name: 'SettlementViewContainer',
      component: SettlementViewContainer,
      meta: { title: '정산서 정보' },
    },
    {
      path: 'invoices',
      name: 'InvoiceListContainer',
      component: InvoiceListContainer,
      meta: { title: '지급서 목록' },
    },
    {
      path: 'invoices/:id',
      name: 'InvoiceViewContainer',
      component: InvoiceViewContainer,
      meta: { title: '지급서 정보' },
    },
    {
      path: 'taxInvoices',
      name: 'TaxInvoiceListContainer',
      component: TaxInvoiceListContainer,
      meta: { title: '세금계산서 목록' },
    },
  ],
};
