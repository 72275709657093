import Content from '@/layout/Content.vue';

const ShortcutSetList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "shortcut" */ '@/domain/shortcut/views/ShortcutSetList.vue'
  );

const ShortcutSetForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "shortcut" */ '@/domain/shortcut/views/ShortcutSetForm.vue'
  );

export const shortPathList = {
  path: '/shortcut',
  name: 'Shortcut',
  redirect: '/shortcut/shortcutset/list',
  component: Content,
  children: [
    {
      path: 'shortcutset/list',
      name: 'ShortcutSetList',
      component: ShortcutSetList,
      meta: { title: '홈 숏컷 관리' },
      props: true,
    },
    {
      path: 'shortcutset/create',
      name: 'ShortcutSetCreate',
      component: ShortcutSetForm,
      meta: { title: '숏컷 등록' },
    },
    {
      path: 'shortcutset/:setId',
      name: 'ShortcutSetUpdate',
      component: ShortcutSetForm,
      meta: { title: '숏컷 수정' },
      props: true,
    },
  ],
};
