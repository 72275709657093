import { DirectiveOptions, VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

export const onlyNumber: DirectiveOptions = {
  bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    el.addEventListener('keypress', (e: KeyboardEvent) => {
      const charCode = e.which ? e.which : e.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        e.preventDefault();
      } else {
        return true;
      }
    });
  },
};
