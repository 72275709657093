<template>
  <footer class="footer px-4 mx-2">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6"> </b-col>
      <b-col lg="6">
        <b-nav align="center" class="nav-footer justify-content-lg-end">
          <!-- <b-nav-item href="https://www.creative-tim.com" target="_blank">
            프립 웹 바로가기
          </b-nav-item> -->
          <div class="text-gray">
            Frip {{ adminText }} version {{ version }}
          </div>
        </b-nav>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
import { version } from '../../package';
import { isHostAdmin } from '@/env';

export default {
  data() {
    return {
      version: version,
      isHostAdmin: isHostAdmin(),
    };
  },
  computed: {
    adminText() {
      return this.isHostAdmin ? 'Host Admin' : 'Admin';
    },
  },
};
</script>
<style></style>
