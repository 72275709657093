











import Vue from 'vue';
import * as Icons from '@/assets/icons';

interface IconsType {
  [name: string]: typeof import('*.svg');
}

export default Vue.extend({
  name: 'Icon',
  inheritAttrs: false,
  props: {
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    name: {
      type: String,
    },
    type: {
      type: String,
      default: 'none',
    },
  },
  data(): { icons: IconsType } {
    return {
      icons: Icons,
    };
  },
  computed: {
    src(): typeof import('*.svg') {
      return this.name ? this.icons[this.name] : this.icons.iconLogo;
    },
    iconColor(): string {
      // https://stackoverflow.com/questions/42966641/how-to-transform-black-into-any-given-color-using-only-css-filters/43960991#43960991
      // https://codepen.io/sosuke/pen/Pjoqqp
      const filterDefault =
        'invert(0%) sepia(6%) saturate(1202%) hue-rotate(360deg) brightness(105%) contrast(87%)';
      // const filterPrimary =
      //   'invert(0%) sepia(0%) saturate(635%) hue-rotate(168deg) brightness(94%) contrast(87%)';
      const filterPrimary =
        'invert(40%) sepia(84%) saturate(6105%) hue-rotate(199deg) brightness(101%) contrast(101%)';
      const filterSecondary =
        'invert(100%) sepia(100%) saturate(0%) hue-rotate(133deg) brightness(101%) contrast(101%)';
      const filterInfo =
        'invert(62%) sepia(84%) saturate(336%) hue-rotate(151deg) brightness(94%) contrast(104%)';
      const filterSuccess =
        'invert(59%) sepia(82%) saturate(438%) hue-rotate(64deg) brightness(89%) contrast(92%)';
      const filterDanger =
        'invert(47%) sepia(58%) saturate(6418%) hue-rotate(341deg) brightness(99%) contrast(103%)';
      const filterWarning =
        'invert(50%) sepia(35%) saturate(5774%) hue-rotate(353deg) brightness(101%) contrast(82%)';

      const typeTofilter: { [key: string]: string } = {
        default: filterDefault,
        primary: filterPrimary,
        secondary: filterSecondary,
        info: filterInfo,
        success: filterSuccess,
        danger: filterDanger,
        warning: filterWarning,
        none: '',
      };
      return typeTofilter?.[this.type.toLowerCase()] || '';
    },
    iconStyle(): string {
      return this.iconColor.length ? `filter: ${this.iconColor};` : '';
    },
  },
});
