var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.paddingClass},[_c('validation-provider',_vm._b({attrs:{"rules":_vm.validation,"name":_vm.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',[_c('b-form-group',{attrs:{"label-sr-only":!_vm.label,"label-cols-md":_vm.labelColsMd,"label-cols-lg":_vm.labelColsLg,"content-cols-md":_vm.contentColsMd,"content-cols-lg":_vm.contentColsLg,"label":_vm.label},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.required)?_c('span',{class:['text-danger', 'position-absolute', 'ml-2']},[_vm._v("*")]):_vm._e(),_c('span',{staticClass:"pl-3",staticStyle:{"font-size":"0.9rem"}},[_vm._v(_vm._s(_vm.label))]),(_vm.question)?_c('span',{staticClass:"text-gray pl-2"},[_c('icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],attrs:{"name":"iconHelp","width":"20","title":_vm.question}})],1):_vm._e()]},proxy:true}],null,true)},[_c('div',{class:[
            { 'input-group': true },
            { focused: _vm.focused },
            { 'input-group-alternative': _vm.alternative },
            { 'has-label': _vm.label || _vm.$slots.label },
            _vm.inputGroupClasses ]},[(_vm.prependText || _vm.$slots.prepend)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("prepend",function(){return [_c('small',[_vm._v(_vm._s(_vm.prependText))])]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control",class:[
                { 'is-valid': valid && validated && _vm.successMessage },
                { 'is-invalid': invalid && validated },
                _vm.inputClasses ],attrs:{"type":_vm.type,"maxlength":_vm.maxLength,"valid":valid,"disabled":_vm.disabled,"required":_vm.required},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,_vm.slotData),(_vm.appendText || _vm.$slots.append)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("append",function(){return [_c('small',[_vm._v(" "+_vm._s(_vm.appendText)+" ")])]})],2)]):(_vm.maxLength)?_c('div',{staticClass:"input-group-append"},[_c('span',{class:[
                'input-group-text',
                { 'append-disabled': _vm.disabled },
                { 'is-invalid': invalid && validated } ]},[_vm._t("append",function(){return [_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.maxLength)+" ")])]})],2)]):_vm._e()],2),_vm._t("success",function(){return [(valid && validated && _vm.successMessage)?_c('div',{staticClass:"valid-feedback"},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e()]}),_vm._t("error",function(){return [(errors[0])?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.invalidMessage !== '' ? _vm.invalidMessage : errors[0])+" ")]):_vm._e()]}),(_vm.caption)?_c('div',{staticClass:"text-muted m-2",staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()],2)],1)]}}],null,true)},'validation-provider',_vm.$attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }