import { ClientPlatform, PageInfo } from '@frientrip/domain';

export interface IntroPopup {
  id: number;
  title: string;
  urls: IntroPopupUrl[];
  url: string;
  content: string;
  image: Content;
  startedAt: Date;
  endedAt: Date;
  exposed: boolean;
  contentType: IntroPopupContentType;
  indexNo: number;
}

export interface Content {
  id: number;
  uri?: string;
}

export interface IntroPopupUrl {
  id: string;
  url: string;
  platform: ClientPlatform;
}

export interface IntroPopupFilter {
  id?: number;
  titleLike?: string;
  exposed?: boolean;
}

export interface IntroPopupConnection {
  edges: IntroPopupEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface IntroPopupEdge {
  node: IntroPopup;
  cursor: string;
}

export const IntroPopupContentType = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
};

export type IntroPopupContentType =
  typeof IntroPopupContentType[keyof typeof IntroPopupContentType];

export interface IntroPopupParam {
  id?: number;
  title: string;
  contentId?: string;
  content?: string;
  startedAt: Date;
  endedAt: Date;
  exposed: boolean;
  urls: IntroPopupUrlParam[] | null;
  indexNo?: number | null;
  contentType: IntroPopupContentType;
}

export interface IntroPopupUrlParam {
  id?: string | null;
  url: string;
  platform: ClientPlatform;
}

export interface IntroPopupChangedIndexNoParam {
  id: string;
  indexNo: number;
}

export interface IntroPopupExposureParam {
  id: string;
  exposed: boolean;
}
