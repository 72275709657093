import Content from '@/layout/Content.vue';

const HostList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/admin/HostList.vue'
  );
const HostForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/admin/HostForm.vue'
  );
const HostProfileForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/hostAdmin/HostUpdateForm.vue'
  );
const HostAccountForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/hostAdmin/HostAccountForm.vue'
  );
const SuperHostTemplate = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/hostAdmin/SuperHostTemplate.vue'
  );

const HostCreateForm = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/admin/HostCreateForm.vue'
  );

const HostBusinessInfoList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/admin/HostBusinessInfoList.vue'
  );
const SuperHostList = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "host" */ '@/domain/host/views/admin/SuperHostBatchRegistrationForm.vue'
  );

export const hostPathList = {
  path: '/host',
  name: 'hostContent',
  redirect: 'list',
  component: Content,
  children: [
    {
      path: 'list',
      name: 'Host List',
      component: HostList,
      meta: { title: '호스트 목록' },
    },
    {
      path: 'list/:id',
      name: 'Host Form',
      component: HostForm,
      meta: { title: '호스트 상세' },
    },
    {
      path: 'create',
      name: 'create Host',
      component: HostCreateForm,
      meta: { title: '호스트 추가' },
    },
    {
      path: 'business-info/list',
      name: 'Host Business Info List',
      component: HostBusinessInfoList,
      meta: { title: '호스트 목록' },
    },
    {
      path: 'super/list',
      name: 'Super Host List',
      component: SuperHostList,
      meta: { title: '슈퍼 호스트 목록' },
    },
  ],
};

export const hostAdminPathList = {
  path: '/host',
  name: 'hostAdminContent',
  redirect: 'list',
  component: Content,
  children: [
    {
      path: 'profile',
      name: 'Host profile',
      component: HostProfileForm,
      meta: { title: '프로필 관리' },
    },
    {
      path: 'account',
      name: 'Host account',
      component: HostAccountForm,
      meta: { title: '정산정보 관리' },
    },
    {
      path: 'super-host',
      name: 'SuperHostTemplate',
      component: SuperHostTemplate,
    },
  ],
};
