import Content from '@/layout/Content.vue';

const BillContainer = (): Promise<typeof import('*.vue')> =>
  import(
    /* webpackChunkName: "mobile" */ '@/domain/bill/views/BillContainer.vue'
  );

export const billPathList = {
  path: '/settlement',
  name: 'Settlement',
  component: Content,
  children: [
    {
      path: '/settlement/bill',
      name: 'Bill',
      component: BillContainer,
      children: [],
    },
  ],
};
