import Content from '@/layout/Content.vue';
const CurationGroupContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/curation/views/CurationGroupContainer.vue');

const CurationContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/curation/views/CurationContainer.vue');

const CurationGroupRegisterContainer = (): Promise<typeof import('*.vue')> =>
  import('@/domain/curation/views/CurationGroupRegisterContainer.vue');

export const curationPathList = {
  path: '/curationGroup',
  name: 'CurationGroupContainer',
  redirect: '/curationGroup/list',
  component: Content,
  children: [
    {
      path: '/curationGroup/list',
      name: 'CurationGroupContainer',
      component: CurationGroupContainer,
      meta: { title: '큐레이션 관리' },
    },
    {
      path: '/curation/detail/:id',
      name: 'CurationContainer',
      component: CurationContainer,
      meta: { title: '큐레이션 관리' },
    },
    {
      path: '/curationGroup/register',
      name: 'CurationGroupRegisterContainer',
      component: CurationGroupRegisterContainer,
      meta: { title: '큐레이션 관리' },
    },
    {
      path: '/curationGroup/register/:id',
      name: 'CurationGroupRegisterContainerId',
      component: CurationGroupRegisterContainer,
      meta: { title: '큐레이션 관리' },
    },
  ],
};
